<template>
    <div class="vh-100  align-items-center ">
        <div class="ttt  " >

            <div class="text-white alt_2 f_28 font_medium" >#DfirstDay</div>
            <div class="alt_1 " ><img src="../../assets/deloitte-logo.png" width="140" /></div>

            <div v-if="openedSoru == 0  && (status == 1 || status == 0) && ekran_puanlar == 0">
                
                <div class="text-center"  >
                    <div class=" f_46 font_semibold color_white" style="padding-top: 150px;">Hoş geldiniz!</div>
                    <div class=" f_30  color_white" style="padding-top: 10px;">Lütfen mobil cihazınız ile aşağıdaki QR kodu okutun.</div>
                    <div style="padding-top: 40px;">
                        <img src="../../assets/deloitte-qr.png" width="390" class="mx-5"   />
                    </div>
                </div>
                <div class=" text-center mt-2 p-2 f_24 font_medium mx-auto color_white" style="width: 500px;">quizylive.com/deloitte</div>

            </div>

            <div v-if="openedSoru >= 1 && status == 1 && ekran_puanlar == 0">

                <div class="text-center"  >
                    
                    <div style="padding-top: 130px; margin-bottom: 20px; " >
                        <div style="width: 200px; " class="soru_d mx-auto f_34 font_medium">SORU {{soru_num}}</div>
                        <div style=" margin-left: 200px; margin-right: 200px;" class="font_semibold mt-4 f_34 color_white">{{soru}}</div>
                    </div>
                    <div class="row mt-5  mx-auto list_bg" style="width: 1200px;">
                        <div v-for="(item,index) in secenekler" :key="index" :class="clasbul(item.dogru)">
                            {{item.secenek}} 
                        </div>
                    </div>

                </div>

                <div class="renks">
                    <div :class="clasbul_renk(0) +  ' takim_kirmizi'">{{ show_res ? this.cevap_kirmizi : "" }}</div>
                    <div :class="clasbul_renk(1) +  ' takim_sari'">{{ show_res ? this.cevap_sari : ""}}</div>
                    <div :class="clasbul_renk(2) +  ' takim_mavi'">{{  show_res ? this.cevap_mavi : ""}}</div>
                    <div :class="clasbul_renk(3) +  ' takim_yesil'">{{ show_res ? this.cevap_yesil : ""}}</div>
                    <div :class="clasbul_renk(4) +  ' takim_mor'">{{ show_res ? this.cevap_mor : ""}}</div>
                    <div :class="clasbul_renk(5) +  ' takim_turuncu'">{{ show_res ? this.cevap_turuncu : "" }}</div>
                </div>

            </div>

            <div v-if="status == 2 && ekran_puanlar == 0">

                <div class="   f_30  " style="overflow: hidden !important; padding-top: 200px;">

                    <div style="width: 300px; " class="soru_d mx-auto f_34 font_medium text-center">SONUÇLAR </div>


                    <div class="text-center color_white font_medium mt-5 pb-2 f_28">
                        Yarışmamızı en yüksek puanla tamamlayan takımları kutlarız.
                    </div>

                    <div class="renksa mt-5  pt-4" >
                        <div><div class=" anlikd  takim_kirmizi"></div><div class="text-white text-center mt-2 f_54 font-weight-bold ">{{t_kirmizi}}</div></div>
                        <div><div class=" anlikd  takim_sari"></div><div class="text-white text-center mt-2 f_54 font-weight-bold ">{{t_sari}}</div></div>
                        <div><div class=" anlikd  takim_mavi"></div><div class="text-white text-center mt-2 f_54 font-weight-bold ">{{t_mavi}}</div></div>
                        <div><div class=" anlikd  takim_yesil"></div><div class="text-white text-center mt-2 f_54 font-weight-bold ">{{t_yesil}}</div></div>
                        <div><div class=" anlikd  takim_mor"></div><div class="text-white text-center mt-2 f_54 font-weight-bold ">{{t_mor}}</div></div>
                        <div><div class=" anlikd  takim_turuncu"></div><div class="text-white text-center mt-2 f_54 font-weight-bold ">{{t_turuncu}}</div></div>
                    </div>



                </div>

            </div>

            <div v-if="ekran_puanlar == 1">

                <div class="   f_30  " style="overflow: hidden !important; padding-top: 200px;">
                    <div style="width: 280px; " class="soru_d mx-auto f_34 font_medium text-center">ANLIK DURUM </div>

                    <div class=" color_white mr-auto mt-3 ml-auto" style="width: 1300px;">


                        <div class="renksa pt-4">
                            <div class="">
                                <div class=" anlikd  takim_kirmizi"></div>
                                <div class="text-white text-center mt-2 f_54 font-weight-bold ">{{t_kirmizi}}</div>
                            </div>
                            <div><div class=" anlikd  takim_sari"></div><div class="text-white text-center mt-2 f_54 font-weight-bold ">{{t_sari}}</div></div>
                            <div><div class=" anlikd  takim_mavi"></div><div class="text-white text-center mt-2 f_54 font-weight-bold ">{{t_mavi}}</div></div>
                            <div><div class=" anlikd  takim_yesil"></div><div class="text-white text-center mt-2 f_54 font-weight-bold ">{{t_yesil}}</div></div>
                            <div><div class=" anlikd  takim_mor"></div><div class="text-white text-center mt-2 f_54 font-weight-bold ">{{t_mor}}</div></div>
                            <div><div class=" anlikd  takim_turuncu"></div><div class="text-white text-center mt-2 f_54 font-weight-bold ">{{t_turuncu}}</div></div>

                        </div>


                        

                    </div>


                </div>

            </div>

        </div>
    </div>
</template>

<script>

import bos from "@/assets/checkbox-bos_beyaz.png"

import { initializeApp } from "firebase/app";
import { getDatabase,get,child,ref,onValue } from "firebase/database";
//import { getDatabase,ref,set,onValue,get,child } from "firebase/database";

const config = {
    apiKey: "AIzaSyDNmy0ZlEDGtMBNtXbTdxBNEi4bPklV1SQ",
    authDomain: "quizylive.firebaseapp.com",
    databaseURL: "https://quizylive-default-rtdb.firebaseio.com",
    projectId: "quizylive",
    storageBucket: "quizylive.appspot.com",
    messagingSenderId: "497024495958",
    appId: "1:497024495958:web:e79a3a7a61d0c313a0e6f7"
};

const f_app = initializeApp(config);
const db = getDatabase(f_app);
const dbRef = ref(db);



const myCss = () => import('@/styles/deloitte.css');
const myCss2 = () => import('@/styles/ekran.css');

export default {

    components:{
    
    },

    data(){
        return {

            componentToDisplay: null,
            checkbox: true,
            checkbox_img : bos,
            language: "tr",
            flag: "turkish",
            company: "deloitte",
            openedSoru:0,
            ekran_puanlar:0,
            ekran_dogru_secenek:0,
            winners: [ ],
            secenekler: [],

            winners_temp: [ ],
            soru: '',
            soru_num: 1,
            status: 0,
            interval : null,
            boya : 1,
            show_res : false,
            ekran_birinci : "",


            cevap_kirmizi : 0,
            cevap_sari : 0,
            cevap_mavi : 0,
            cevap_yesil : 0,
            cevap_mor : 0,
            cevap_turuncu : 0,

            t_kirmizi : 0,
            t_sari : 0,
            t_mavi : 0,
            t_yesil : 0,
            t_mor : 0,
            t_turuncu : 0,
            
            
        }
    },

    computed: {

        currentTerm: function() {
            if (this.componentToDisplay) {
                return () => import(`@/translate/${this.componentToDisplay}`);
            }
            return null;
        }

    },

    async created() {


        let res = await import("@/translate/"+this.$route.meta.company+".json");
        let res2 = JSON.stringify(res);
        let translate = JSON.parse(res2);

        let translate_icerik = translate.tr;
        if(this.language == "en"){
            translate_icerik = translate.en;
        }

        this.text_login_hos = translate_icerik.login_hos;
        this.text_login_sub = translate_icerik.login_sub;
        this.text_giris_kodunuz = translate_icerik.giris_kodunuz;
        this.text_sozlesme = translate_icerik.sozlesme;
        this.text_login_toast_title = translate_icerik.login_toast_title;
        this.text_login_toast_katilim = translate_icerik.login_toast_katilim;
        this.text_login_toast_email = translate_icerik.login_toast_email;
        this.logo = translate_icerik.logo;
        this.firma_logo = translate_icerik.firma_logo;
        this.checkbox_type = translate_icerik.checkbox_type;

        this.checkbox_img = bos;



        let resultFromgetA = await this.final_hesapla()

        console.log(resultFromgetA);
        

        this.winners = resultFromgetA.sort(this.compare);
        
    },

    methods:{

        compare( a, b ) {
            if ( a.puan < b.puan ){
                return 1;
            }
            if ( a.puan > b.puan ){
                return -1;
            }
            return 0;
        },

        final_hesapla : async() => {

            let ww = []
            
            await get(child(dbRef,"deloitte/takim_kirmizi")).then((snapshot) => {
                ww.push({
                    puan: snapshot.val(),
                    name:"Kırmızı Takım",
                    renk:"kirmizi",
                })

            })
            await  get(child(dbRef,"deloitte/takim_sari")).then((snapshot) => {
                
                ww.push({
                    puan: snapshot.val(),
                    name:"Sarı Takım",
                    renk:"sari",
                })
            })
            await get(child(dbRef,"deloitte/takim_mavi")).then((snapshot) => {
                
                ww.push({
                    puan: snapshot.val(),
                    name:"Mavi Takım",
                    renk:"mavi",
                })
            })
            await get(child(dbRef,"deloitte/takim_mor")).then((snapshot) => {
                
                ww.push({
                    puan: snapshot.val(),
                    name:"Mor Takım",
                    renk:"mor",
                })
            })
            await get(child(dbRef,"deloitte/takim_yesil")).then((snapshot) => {
                
                ww.push({
                    puan: snapshot.val(),
                    name:"Yeşil Takım",
                    renk:"yesil",
                })
            })
            await get(child(dbRef,"deloitte/takim_turuncu")).then((snapshot) => {
                
                ww.push({
                    puan: snapshot.val(),
                    name:"Turuncu Takım",
                    renk:"turuncu",
                })
            })

            //console.log(ww);
            //this.winners = ww

            return  ww


        },  

        

        clasbul(gelen){
            let res = "f_30 font_medium  seces col-6";
            if(gelen == "1" && this.ekran_dogru_secenek == "1"){
                res=" f_30 font_medium  seces_d col-6";
            }
            return res;
        },

        clasbul_renk(gelen){
            let res = "";

            if(this.ekran_dogru_secenek === 0){

                res = "op50";
            }else{

                if(this.show_res){
                    res = "op50";
                    if(gelen == 0){
                        if(this.ekran_birinci.includes("kirmizi")){res=""}
                    }
                    if(gelen == 1){
                        if(this.ekran_birinci.includes("sari")){res=""}
                    }
                    if(gelen == 2){
                        if(this.ekran_birinci.includes("mavi")){res=""}
                    }
                    if(gelen == 3){
                        if(this.ekran_birinci.includes("yesil")){res=""}
                    }
                    if(gelen == 4){
                        if(this.ekran_birinci.includes("mor")){res=""}
                    }
                    if(gelen == 5){
                        if(this.ekran_birinci.includes("turuncu")){res=""}
                    }
    
                    
                }else{
                    res = "op50";
                    if(this.boya % 6 === gelen ){
                        res="";
                    }
    
                }
            }



            return res;
        },

        getCompany(){
            this.company = this.$route.meta.company
            console.log(this.$route.meta.company);
            myCss(this.$route.meta.company);
            myCss2();

            const refactiveSoru = ref(db, "deloitte/openedSoru");

            onValue(refactiveSoru, (snapshot) => {
                const data = snapshot.val();
                this.openedSoru = data
                this.getQuestion(data);

                this.show_res = false;
                this.boya = 0;

            });

            const sttdeloittesSoru = ref(db, "deloitte/status");

            onValue(sttdeloittesSoru, (snapshot) => {
                this.status = snapshot.val();
                if(snapshot.val() == 2){
                    this.winners = this.final_hesapla();
                }
            });

            const statusekran_puanlar = ref(db, "deloitte/ekran_puanlar");

            onValue(statusekran_puanlar, (snapshot) => {
                this.ekran_puanlar = snapshot.val();

                if(snapshot.val() == 1){
                    //this.winnders_temp_do();
                }
            });





            const stdeloittedekran_dogru_secenek = ref(db, "deloitte/ekran_dogru_secenek");
            onValue(stdeloittedekran_dogru_secenek, (snapshot) => {
                console.log("ddd");
                console.log(snapshot.val());
                this.ekran_dogru_secenek = snapshot.val();


                if(snapshot.val() === 1){
                    this.runCevapAnim();
                }else{
                    clearInterval(this.interval);
                }
            });


            const stdeloittedekran_ekran_birinci = ref(db, "deloitte/ekran_birinci");
            onValue(stdeloittedekran_ekran_birinci, (snapshot) => {
                this.ekran_birinci = snapshot.val();
            });



            const tkirmizi = ref(db, "deloitte/takim_kirmizi");
            onValue(tkirmizi, (snapshot) => {
                this.t_kirmizi = snapshot.val();
            });
            const tmavi = ref(db, "deloitte/takim_mavi");
            onValue(tmavi, (snapshot) => {
                this.t_mavi = snapshot.val();
            });
            const tmor = ref(db, "deloitte/takim_mor");
            onValue(tmor, (snapshot) => {
                this.t_mor = snapshot.val();
            });
            const tturuncu = ref(db, "deloitte/takim_turuncu");
            onValue(tturuncu, (snapshot) => {
                this.t_turuncu = snapshot.val();
            });
            const tsari = ref(db, "deloitte/takim_sari");
            onValue(tsari, (snapshot) => {
                this.t_sari = snapshot.val();
            });
            const tyesil = ref(db, "deloitte/takim_yesil");
            onValue(tyesil, (snapshot) => {
                this.t_yesil = snapshot.val();
            });


        },

        runCevapAnim(){
            const self = this;          
            this.interval = setInterval(function(){


                self.boya = self.boya + 1;

                if(self.boya > 100){
                    self.getQuestion(self.openedSoru)
                    self.show_res = true;
                    clearInterval(self.interval);
                    self.boya = 0;
                }

            }, 80);


        },  



        getQuestion(soruId){
            let nRef = "deloitte/questions/"+soruId;
            get(child(dbRef, nRef)).then((snapshot) => {
                this.cinko1Users = [];

                this.soru = snapshot.val().question;
                this.soru_num = soruId;
                this.secenekler = snapshot.val().secenekler;

                console.log(snapshot.val());
                
                this.cevap_kirmizi = snapshot.val().kirmizi;
                this.cevap_mavi = snapshot.val().mavi;
                this.cevap_mor = snapshot.val().mor;
                this.cevap_turuncu = snapshot.val().turuncu;
                this.cevap_yesil = snapshot.val().yesil;
                this.cevap_sari = snapshot.val().sari;
                
            }).catch((error) => {
                console.error(error);
            });

        },

        winnders_temp_do() {
            

            let nRef = this.company+"/winners_temp";
            get(child(dbRef, nRef)).then((snapshot) => {
                this.winners_temp = [];

                snapshot.forEach(e => {

                    console.log(e);

                    let temp = {
                        userId:e.key,
                        puan: e.val().puan + " Puan",
                        name:e.val().name,
                        email:e.val().email
                    };
                    this.winners_temp.push(temp);

                }); 
            }).then(() => {
                let temp = {
                    userId:"",
                    puan: "",
                    name:"",
                    email:""
                };
                this.winners_temp.push(temp);
                this.winners_temp.push(temp);
                this.winners_temp.push(temp);
                this.winners_temp.push(temp);
                this.winners_temp.push(temp);
                this.winners_temp.push(temp);
                this.winners_temp.push(temp);
                this.winners_temp.push(temp);
                this.winners_temp.push(temp);
                this.winners_temp.push(temp);


            }).catch((error) => {
                console.error(error);
            });
        },

        winnders() {
            let nRef = this.company+"/winners";
            get(child(dbRef, nRef)).then((snapshot) => {
                this.winners = [];
                snapshot.forEach(e => {

                    let temp = {
                        userId:e.key,
                        puan: e.val().puan + " Puan",
                        name:e.val().name,
                        email:e.val().email
                    };
                    this.winners.push(temp);

                }); 
            }).then(() => {
                let temp = {
                    userId:"",
                    puan: "",
                    name:"",
                    email:""
                };
                this.winners.push(temp);
                this.winners.push(temp);
                this.winners.push(temp);
                this.winners.push(temp);
                this.winners.push(temp);
                this.winners.push(temp);
                this.winners.push(temp);
                this.winners.push(temp);
                this.winners.push(temp);
                this.winners.push(temp);


            }).catch((error) => {
                console.error(error);
            });
        }


        




    },

    mounted () {
        this.getCompany();

    },

    


};


</script>

