
<template>
<div class="container cont_kisa color_white mt-5">
    
    
    <b-row class="my-1">
        <b-col sm="4">
            <label for="input-small">Oyun:</label>
        </b-col>
        <b-col sm="8">
        <b-form-input v-model="name" id="input-small" size="sm" ></b-form-input>
        </b-col>
    </b-row>



    <b-row class="my-1">
        <b-col sm="4">
            <label for="input-small">Dosya:</label>
        </b-col>
        <b-col sm="8">
        <b-form-file v-model="file2" class="mt-3" plain></b-form-file>
        </b-col>
    </b-row>

   


    <b-row class="my-1">
        <b-col class="text-right" sm="12">
            <a href="#" @click="readFile" class="btn btn-primary">EKLE</a>
        </b-col>
    </b-row>
</div>
</template>


<style lang="scss">
@import '~@/styles/home.css';
</style>

<script>


import { initializeApp } from "firebase/app";
import { getDatabase,set,ref } from "firebase/database";


const config = {
    apiKey: "AIzaSyDNmy0ZlEDGtMBNtXbTdxBNEi4bPklV1SQ",
    authDomain: "quizylive.firebaseapp.com",
    databaseURL: "https://quizylive-default-rtdb.firebaseio.com",
    projectId: "quizylive",
    storageBucket: "quizylive.appspot.com",
    messagingSenderId: "497024495958",
    appId: "1:497024495958:web:e79a3a7a61d0c313a0e6f7"
};


const f_app = initializeApp(config);
const db = getDatabase(f_app);

//const dbRef = ref(db);


export default {

    components:{
        //HeaderBar
    },

    data(){
        return {
            name: "deloitte",
            file2: null,
            fileinput: ""
        }
    },

    methods:{
        addQuestions(){

            var mydata = JSON.parse(this.fileinput);
            console.log("mydata");
            console.log(mydata);

            let i = 1;
            mydata.questions.forEach(element => {
                console.log(element.soru);
                let seceneks = [];
                element.secenekler.forEach( tt=> {
                    console.log(tt.secenek);
                    seceneks.push({
                        dogru : tt.dogru,
                        secenek : tt.secenek,
                        secildi : 0
                    });
                });

                
                set(ref(db, "deloitte/questions/"+i), {
                    questionId : this.randName(),
                    question: element.soru,
                    opened: 0,
                    dateBasla: 0,
                    secenekler:seceneks,
                    kirmizi: 0,
                    mavi: 0,
                    mor: 0,
                    turuncu: 0,
                    sari: 0,
                    yesil: 0
                });

                i++
                
            });
            
            

        },

        readFile: function() {
            const scope = this
            var promiseVar = new Promise( (resolve, reject) => {
                try {
                    var reader = new FileReader();
                    var file = this.file2;
                    reader.readAsText(file);
                    reader.onload = function(e) {
                        scope.fileinput = e.target.result
                        resolve('complete');
                    };
                } catch (error) {
                    console.error(error);
                    reject("There must be an error");
                }
            });
            // let updateds = await Promise.all(promiseVar);
            promiseVar.then(data=>{
                scope.afterRead();
                console.log(data);
            })
        },

        afterRead:function(){
            this.addQuestions();
        },

        randName(){
            var result           = '';
            var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < 15; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
            }
            return result;
        }
        
    },

    mounted () {
        //this.getWeatherData()
    },
};


</script>

