<template>
<div class="">
    <div class="container dw640 pb_150 color_white">


        <div class=" mt-5">
            <a href="#" class="btn button_tur py-1 f_20 w600  pr-3 pointer_normal">SÜRE</a>
        </div>

        <div class="mt-3 mb-5 text-center" >
            <div class="text-center">
                <b-progress class="barH" :value="timeRemaining" :max="15" ></b-progress>
            </div>
            <div class="f_14  text-center mt-2">Kalan Süre: {{timeRemaining}}sn</div>
        </div>


        <div class="mt-4 mb-4 f_20 ">{{soru}}</div>




        <div v-for="(item,index) in secenekler" :key="index">
            
            <a @click="answerQuestion(index)" :class="secClass(index)">
                {{item.secenek}}
            </a>

        </div>

        

    </div>
</div>
</template>

<style>

</style>

<script>



import { initializeApp } from "firebase/app";
import { getDatabase,onValue,ref,get,child,set } from "firebase/database";

//const confetti = require('canvas-confetti');

const config = {
    apiKey: "AIzaSyDNmy0ZlEDGtMBNtXbTdxBNEi4bPklV1SQ",
    authDomain: "quizylive.firebaseapp.com",
    databaseURL: "https://quizylive-default-rtdb.firebaseio.com",
    projectId: "quizylive",
    storageBucket: "quizylive.appspot.com",
    messagingSenderId: "497024495958",
    appId: "1:497024495958:web:e79a3a7a61d0c313a0e6f7"
};

const f_app = initializeApp(config);
const db = getDatabase(f_app);
const dbRef = ref(db);


const myCss = () => import('@/styles/deloitte.css');


export default {

    data(){
        return {

            language: "en",
            company: "deloitte",
            email: '',
            uid: '',
            name: '',
            gamestatus: 1,
            totalSoru: 16,
            openedSoru:0,
            puan: 0,
            takim: "",

            proje: '',
            soru: '',
            secenekler: [],
            dateBasla: 0,

            timeStarted : 0,
            timeRemaining : 15,

            interval : null,

            clickedSec : -1


        }
    },

    created() {

        const self = this;          
        this.interval = setInterval(function(){

                let gecen = Math.round(new Date().getTime()/1000) - self.timeStarted;
                self.timeRemaining = 15-gecen;

                    
                if (self.timeRemaining <= 0){
                    self.answerQuestion(-1);
                }

        }, 1000);

    },

    methods:{

        limitText(text,len){
            return  text.length > len ? text.substring(0, len - 3) + "..." : text;
        },

        answerQuestion(index){

            let runningQuestion =  this.$store.getters.get_runningQuestion;

            if(runningQuestion == 1){  this.$store.commit('set_q1_answered', 1); }
            if(runningQuestion == 2){  this.$store.commit('set_q2_answered', 1); }
            if(runningQuestion == 3){  this.$store.commit('set_q3_answered', 1); }
            if(runningQuestion == 4){  this.$store.commit('set_q4_answered', 1); }
            if(runningQuestion == 5){  this.$store.commit('set_q5_answered', 1); }
            if(runningQuestion == 6){  this.$store.commit('set_q6_answered', 1); }
            if(runningQuestion == 7){  this.$store.commit('set_q7_answered', 1); }
            if(runningQuestion == 8){  this.$store.commit('set_q8_answered', 1); }
            if(runningQuestion == 9){  this.$store.commit('set_q9_answered', 1); }
            if(runningQuestion == 10){  this.$store.commit('set_q10_answered', 1); }
            if(runningQuestion == 11){  this.$store.commit('set_q11_answered', 1); }
            if(runningQuestion == 12){  this.$store.commit('set_q12_answered', 1); }
            if(runningQuestion == 13){  this.$store.commit('set_q13_answered', 1); }
            if(runningQuestion == 14){  this.$store.commit('set_q14_answered', 1); }
            if(runningQuestion == 15){  this.$store.commit('set_q15_answered', 1); }
            if(runningQuestion == 16){  this.$store.commit('set_q16_answered', 1); }
            if(runningQuestion == 17){  this.$store.commit('set_q17_answered', 1); }
            if(runningQuestion == 18){  this.$store.commit('set_q18_answered', 1); }
            if(runningQuestion == 19){  this.$store.commit('set_q19_answered', 1); }
            if(runningQuestion == 20){  this.$store.commit('set_q20_answered', 1); }
            
            //console.log("index" + index);

            if(index >= 0 && this.clickedSec == -1){

                this.clickedSec = index;

                let dogru = this.secenekler[index].dogru;
                //console.log("doğru " + dogru);
                if(dogru == 1){
                    
                    //let ekpuan = (this.timeRemaining*10)  + Math.floor(Math.random() * 10)
                    //if(ekpuan >=450){ekpuan = 448;}
                    this.puan  = this.puan + 1;
                    set(ref(db, this.company+"/users/"+ this.uid+"/puan"),  this.puan);
                    set(ref(db, this.company+"/users/"+ this.uid+"/puankazan"),  1);
                }else{
                    set(ref(db, this.company+"/users/"+ this.uid+"/puankazan"),  0);
                }


                set(ref(db, "deloitte/cevaplar/"+this.randName()), {
                    uid : this.uid,
                    question: runningQuestion,
                    index: index,
                    dogru: dogru,
                    takim: this.takim,
                });



            }

            clearInterval(this.interval);
            setTimeout(() => this.$router.push('/deloitte/lobby'), 1000);
            
        },

        getQuestion(soruId){
            let nRef = this.company+"/questions/"+soruId;
            get(child(dbRef, nRef)).then((snapshot) => {
                this.cinko1Users = [];

                this.soru = snapshot.val().question;

                this.secenekler = [];

                snapshot.val().secenekler.forEach(e => {

                    let temp = {
                        secenek: e.secenek,
                        dogru: e.dogru
                    };
                    this.secenekler.push(temp);
                }); 
                
            }).catch((error) => {
                console.error(error);
            });

        },

        randomInRange(min, max) {
            return Math.random() * (max - min) + min;
        },
        findNow(){
            return Math.round(new Date().getTime()/1000);
        },

        getCompany(){
            this.company = this.$route.meta.company
            myCss(this.$route.meta.company);
        },
        showToast(gelen,title){
            this.$bvToast.toast(gelen, {
                title: title,
                autoHideDelay: 3000,
                appendToast: true,
                toaster: "b-toaster-bottom-right",
                variant:"info",
                solid: true,
            })
        },

        connectDb(){

            const refactiveSoru = ref(db, this.company+"/openedSoru");

            onValue(refactiveSoru, (snapshot) => {
                const data = snapshot.val();
                this.openedSoru = data
                this.getQuestion(data);

            });


            const refTakim = ref(db, "deloitte/users/"+this.uid+"/takim");
            onValue(refTakim, (snapshot) => {
                const data = snapshot.val();
                this.takim = data
            });

            const refPuan = ref(db, this.company+"/users/"+this.uid+"/puan");
            onValue(refPuan, (snapshot) => {
                const data = snapshot.val();
                this.puan = data
            });

            const refdateBasla = ref(db, this.company+"/questions/"+this.openedSoru+"/dateBasla");
            onValue(refdateBasla, (snapshot) => {
                const data = snapshot.val();
                this.dateBasla = data
            });
        },

        secClass(index){
            let cls = "btn  btn-block mt-3 pt-3 pb-3 sece  font_semibold  ";
            if(index == this.clickedSec && this.clickedSec != -1){
                cls = "btn  btn-block mt-3 pt-3 pb-3 sece_active  font_semibold ";
            }
            return cls
        },

        randName(){
            var result           = '';
            var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < 15; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
            }
            return result;
        }
        
    
        

    },

    watch: {
        lastNumber : function(val, oldVal){
            if (val != oldVal) {
                this.animateCSS('.lastN', 'bounceIn');
            }
        },
        

    },

    beforeDestroy () {
        clearInterval(this.interval)
    },


    mounted () {

        this.language =  this.$store.getters.getLanguage;
        this.email =  this.$store.getters.getEmail;
        this.uid =  this.$store.getters.getUid;
        this.name =  this.$store.getters.getName;

        let runningQuestion =  this.$store.getters.get_runningQuestion;

        let ttime = 0;
        let cevaplandi = "";

        if(runningQuestion == 1){  ttime = this.$store.getters.get_q1_time; cevaplandi = this.$store.getters.get_q1_answered;}
        if(runningQuestion == 2){  ttime = this.$store.getters.get_q2_time; cevaplandi = this.$store.getters.get_q2_answered;}
        if(runningQuestion == 3){  ttime = this.$store.getters.get_q3_time; cevaplandi = this.$store.getters.get_q3_answered;}
        if(runningQuestion == 4){  ttime = this.$store.getters.get_q4_time; cevaplandi = this.$store.getters.get_q4_answered;}
        if(runningQuestion == 5){  ttime = this.$store.getters.get_q5_time; cevaplandi = this.$store.getters.get_q5_answered;}
        if(runningQuestion == 6){  ttime = this.$store.getters.get_q6_time; cevaplandi = this.$store.getters.get_q6_answered;}
        if(runningQuestion == 7){  ttime = this.$store.getters.get_q7_time; cevaplandi = this.$store.getters.get_q7_answered;}
        if(runningQuestion == 8){  ttime = this.$store.getters.get_q8_time; cevaplandi = this.$store.getters.get_q8_answered;}
        if(runningQuestion == 9){  ttime = this.$store.getters.get_q9_time; cevaplandi = this.$store.getters.get_q9_answered;}
        if(runningQuestion == 10){  ttime = this.$store.getters.get_q10_time; cevaplandi = this.$store.getters.get_q10_answered;}
        if(runningQuestion == 11){  ttime = this.$store.getters.get_q11_time; cevaplandi = this.$store.getters.get_q11_answered;}
        if(runningQuestion == 12){  ttime = this.$store.getters.get_q12_time; cevaplandi = this.$store.getters.get_q12_answered;}
        if(runningQuestion == 13){  ttime = this.$store.getters.get_q13_time; cevaplandi = this.$store.getters.get_q13_answered;}
        if(runningQuestion == 14){  ttime = this.$store.getters.get_q14_time; cevaplandi = this.$store.getters.get_q14_answered;}
        if(runningQuestion == 15){  ttime = this.$store.getters.get_q15_time; cevaplandi = this.$store.getters.get_q15_answered;}
        if(runningQuestion == 16){  ttime = this.$store.getters.get_q16_time; cevaplandi = this.$store.getters.get_q16_answered;}
        if(runningQuestion == 17){  ttime = this.$store.getters.get_q17_time; cevaplandi = this.$store.getters.get_q17_answered;}
        if(runningQuestion == 18){  ttime = this.$store.getters.get_q18_time; cevaplandi = this.$store.getters.get_q18_answered;}
        if(runningQuestion == 19){  ttime = this.$store.getters.get_q19_time; cevaplandi = this.$store.getters.get_q19_answered;}
        if(runningQuestion == 20){  ttime = this.$store.getters.get_q20_time; cevaplandi = this.$store.getters.get_q20_answered;}

        
        this.timeStarted = ttime;


        if(cevaplandi != ""){
            this.$router.push('/deloitte/lobby');
        }

        this.getCompany();
        this.connectDb();


    },
    
    
    
}
</script>