<template>
    <div class="logo_bg">
        <div class="container dw640 pb_150 color_white">

            <div class=" f_18 w500 isim_bg position-absolute py-2 pl-2 pr-3">
                {{limitText(name,23)}}
            </div>
            <div :class="secClassTakim()">
                {{
                    takim === "mavi" ? "Mavi" 
                    : takim === "kirmizi" ? "Kırmızı" 
                    : takim === "sari" ? "Sarı" 
                    : takim === "mor" ? "Mor" 
                    : takim === "turuncu" ? "Turuncu" 
                    : takim === "yesil" ? "Yeşil" 
                    : ""}}
            </div>

            <div v-if="openedSoru == 0" class="pt-5 ">


                <div class="mt-5 pt-5 text-center"><img src="../../assets/kum-saati.png" width="90"></div>
                <div class="mt-2 text-center w600 f_24">Lütfen bekleyiniz.</div>
                <div class="f_18 mt-1 text-center">Yarışmamız birazdan başlayacak.</div>


            </div>

            <div v-if="openedSoru == totalSoru" class="text-center pt-5">


                <div v-if="puankazan >= 1" class="mt-5 pt-5  text-center ">
                    <img src="../../assets/dogru-cevap.png" width="100">
                    <div class="f_24  mt-2 font_semibold">Doğru Cevap</div>
                </div>
                
                <div v-if="puankazan == 0" class="mt-5 pt-5  text-center ">
                    <img src="../../assets/yanlis-cevap.png" width="100">
                    <div class="f_24  mt-2 font_semibold">Yanlış Cevap</div>
                </div>


                <div class=" text-center mt-1  f_18">Birazdan final sayfasına yönlendirileceksiniz.</div>


            </div>


            <div class="pt-5" v-if="openedSoru >= 1 && openedSoru < totalSoru">


                <div v-if="puankazan >= 1" class="mt-5 pt-5 text-center ">
                    <img src="../../assets/dogru-cevap.png" width="100">
                    <div class="f_24  mt-2 font_semibold">Doğru Cevap</div>
                    
                </div>
                
                <div v-if="puankazan == 0" class="mt-5 pt-5 text-center ">
                    <img src="../../assets/yanlis-cevap.png" width="100">
                    <div class="f_24  mt-2 font_semibold">Yanlış Cevap</div>
                    
                </div>

                <div class=" text-center mt-1  f_18">Sonraki soru için lütfen bekleyin.</div>




            </div>



















            <div class='text-center position-absolute' style="bottom:30px; left:0; right:0;">
                <img src="../../assets/deloitte-logo.png" width="140">
            </div>
            



        </div>
    </div>
</template>

<script>

import { initializeApp } from "firebase/app";
import { getDatabase,onValue,ref,serverTimestamp } from "firebase/database";

const config = {
    apiKey: "AIzaSyDNmy0ZlEDGtMBNtXbTdxBNEi4bPklV1SQ",
    authDomain: "quizylive.firebaseapp.com",
    databaseURL: "https://quizylive-default-rtdb.firebaseio.com",
    projectId: "quizylive",
    storageBucket: "quizylive.appspot.com",
    messagingSenderId: "497024495958",
    appId: "1:497024495958:web:e79a3a7a61d0c313a0e6f7"
};
const f_app = initializeApp(config);
const db = getDatabase(f_app);
//const dbRef = ref(db);


const myCss = () => import('@/styles/deloitte.css');


export default {

    data(){
        return {

            language: "tr",
            company: "deloitte",
            email: '',
            uid: '',
            name: '',
            timeS: serverTimestamp(),
            gamestatus: 1,
            totalSoru: 16,
            openedSoru:0,
            puan: 0,
            puankazan: 0,
            takim: "",

            logo: '',
            refresh_type: 'beyaz',

        }
    },



    async created() {

        let res = await import("@/translate/"+this.$route.meta.company+".json");
        let res2 = JSON.stringify(res);
        let translate = JSON.parse(res2);

        let translate_icerik = translate.en;

        this.logo = translate_icerik.logo;

    },

    methods:{

        secClassTakim(){
            let ek = ""
            if(this.takim === "kirmizi"){ ek="takim_kirmizi" }
            if(this.takim === "yesil"){ ek="takim_yesil" }
            if(this.takim === "mavi"){ ek="takim_mavi" }
            if(this.takim === "sari"){ ek="takim_sari" }
            if(this.takim === "mor"){ ek="takim_mor" }
            if(this.takim === "turuncu"){ ek="takim_turuncu" }

            let cls = "f_18 w500 puan_bg position-absolute py-2 pr-2 pl-3 " + ek;

            console.log("cls");
            

            return cls
        },

        getCompany(){
            //this.company = this.$route.meta.company
            //console.log(this.$route.meta.company);
            myCss(this.$route.meta.company);
        },

        limitText(text,len){
            return  text.length > len ? text.substring(0, len - 3) + "..." : text;
        },

        connectDb(){

            const refPuan = ref(db, "deloitte/users/"+this.uid+"/puan");
            onValue(refPuan, (snapshot) => {
                const data = snapshot.val();
                this.puan = data
            });
            const refTakim = ref(db, "deloitte/users/"+this.uid+"/takim");
            onValue(refTakim, (snapshot) => {
                const data = snapshot.val();
                this.takim = data
            });

            const refPuanKazan = ref(db, "deloitte/users/"+this.uid+"/puankazan");
            onValue(refPuanKazan, (snapshot) => {
                const data = snapshot.val();
                this.puankazan = data
            });

            const refgamestatus = ref(db, "deloitte/status");
            onValue(refgamestatus, (snapshot) => {
                const data = snapshot.val();
                console.log(data);
                if(data == 0){
                    this.$router.push('/deloitte');
                }
                this.gamestatus = data
            });
            
            const reftotalSoru = ref(db, "deloitte/totalSoru");
            onValue(reftotalSoru, (snapshot) => {
                const data = snapshot.val();
                this.totalSoru = data
            });

            const refactiveSoru = ref(db, "deloitte/openedSoru");
            onValue(refactiveSoru, (snapshot) => {
                const data = snapshot.val();
                this.openedSoru = data
            });


        },
        showToast(gelen){
            this.$bvToast.toast(gelen, {
                title: this.text_login_toast_title,
                autoHideDelay: 3000,
                appendToast: true,
                variant: 'danger',
                solid: true,
            })
        },

        animateCSS(element, animation, prefix = 'animate__') {
            new Promise((resolve) => {
                const animationName = `${prefix}${animation}`;
                const node = document.querySelector(element);
                node.classList.add(`${prefix}animated`, animationName);
                function handleAnimationEnd(event) {
                    event.stopPropagation();
                    node.classList.remove(`${prefix}animated`, animationName);
                    resolve('Animation ended');
                }
                node.addEventListener('animationend', handleAnimationEnd, {once: true});
            });
        },

        goToGame(){

            if(this.openedSoru == 1){this.$store.commit('set_q1_time', this.findNow());}
            if(this.openedSoru == 2){this.$store.commit('set_q2_time', this.findNow());}
            if(this.openedSoru == 3){this.$store.commit('set_q3_time', this.findNow());}
            if(this.openedSoru == 4){this.$store.commit('set_q4_time', this.findNow());}
            if(this.openedSoru == 5){this.$store.commit('set_q5_time', this.findNow());}
            if(this.openedSoru == 6){this.$store.commit('set_q6_time', this.findNow());}
            if(this.openedSoru == 7){this.$store.commit('set_q7_time', this.findNow());}
            if(this.openedSoru == 8){this.$store.commit('set_q8_time', this.findNow());}
            if(this.openedSoru == 9){this.$store.commit('set_q9_time', this.findNow());}
            if(this.openedSoru == 10){this.$store.commit('set_q10_time', this.findNow());}
            if(this.openedSoru == 11){this.$store.commit('set_q11_time', this.findNow());}
            if(this.openedSoru == 12){this.$store.commit('set_q12_time', this.findNow());}
            if(this.openedSoru == 13){this.$store.commit('set_q13_time', this.findNow());}
            if(this.openedSoru == 14){this.$store.commit('set_q14_time', this.findNow());}
            if(this.openedSoru == 15){this.$store.commit('set_q15_time', this.findNow());}
            if(this.openedSoru == 16){this.$store.commit('set_q16_time', this.findNow());}
            if(this.openedSoru == 17){this.$store.commit('set_q17_time', this.findNow());}
            if(this.openedSoru == 18){this.$store.commit('set_q18_time', this.findNow());}
            if(this.openedSoru == 19){this.$store.commit('set_q19_time', this.findNow());}
            if(this.openedSoru == 20){this.$store.commit('set_q20_time', this.findNow());}


            //console.log("openedSoru "+ this.openedSoru);
            
            this.$router.push('/deloitte/game');

        },
        findNow(){
            return Math.round(new Date().getTime()/1000);
        },

        

    },

    mounted () {
        this.getCompany();
        

        this.language =  this.$store.getters.getLanguage;
        this.email =  this.$store.getters.getEmail;
        this.uid =  this.$store.getters.getUid;
        this.name =  this.$store.getters.getName;

        this.connectDb();

        

    },

    watch: {

        gamestatus:function(val){
            if (val == 2) {
                this.$router.push('/deloitte/final');
            }
        },


        openedSoru:function(val){

            let runningQuestion =  val;

            let isAnswered = 0;
            if(runningQuestion == 1){isAnswered =  this.$store.getters.get_q1_answered;}
            if(runningQuestion == 2){isAnswered =  this.$store.getters.get_q2_answered;}
            if(runningQuestion == 3){isAnswered =  this.$store.getters.get_q3_answered;}
            if(runningQuestion == 4){isAnswered =  this.$store.getters.get_q4_answered;}
            if(runningQuestion == 5){isAnswered =  this.$store.getters.get_q5_answered;}
            if(runningQuestion == 6){isAnswered =  this.$store.getters.get_q6_answered;}
            if(runningQuestion == 7){isAnswered =  this.$store.getters.get_q7_answered;}
            if(runningQuestion == 8){isAnswered =  this.$store.getters.get_q8_answered;}
            if(runningQuestion == 9){isAnswered =  this.$store.getters.get_q9_answered;}
            if(runningQuestion == 10){isAnswered =  this.$store.getters.get_q10_answered;}
            if(runningQuestion == 11){isAnswered =  this.$store.getters.get_q11_answered;}
            if(runningQuestion == 12){isAnswered =  this.$store.getters.get_q12_answered;}
            if(runningQuestion == 13){isAnswered =  this.$store.getters.get_q13_answered;}
            if(runningQuestion == 14){isAnswered =  this.$store.getters.get_q14_answered;}
            if(runningQuestion == 15){isAnswered =  this.$store.getters.get_q15_answered;}
            if(runningQuestion == 16){isAnswered =  this.$store.getters.get_q16_answered;}
            if(runningQuestion == 17){isAnswered =  this.$store.getters.get_q17_answered;}
            if(runningQuestion == 18){isAnswered =  this.$store.getters.get_q18_answered;}
            if(runningQuestion == 19){isAnswered =  this.$store.getters.get_q19_answered;}
            if(runningQuestion == 20){isAnswered =  this.$store.getters.get_q20_answered;}



            if (val != 0 && isAnswered == 0) {
                
                this.goToGame();
            }

            this.$store.commit('set_runningQuestion', val);
        },
        

    },
    
    
    
}
</script>