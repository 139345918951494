import Vue from "vue";
import VueRouter from "vue-router";


import S3_Game from './components/deloitte/Game';
import S3_Final from './components/deloitte/Final';
import S3_Rapor from './components/deloitte/Rapor';
import S3_Sunucu from './components/deloitte/Sunucu';
import S3_MasterPlay from './components/deloitte/MasterPlay';
import S3_Lobby from './components/deloitte/Lobby';
import S3_Login from './components/deloitte/Login';
import S3_Takim from './components/deloitte/Takim';
import S3_SunucuFinal from './components/deloitte/SunucuFinal';
import S3_Ekran from './components/deloitte/Ekran';


import Teknoted_addGame from './components/Teknoted_addGame';
import Teknoted_addUsers from './components/Teknoted_addUsers';
import Teknoted_addQuestions from './components/Teknoted_addQuestions';
import Teknoted_test from './components/Teknoted_test';



Vue.use(VueRouter);

const routes = [

    //website
    

    { name: "Teknoted_addGame", component : Teknoted_addGame,  path : '/teknoted_addGame' },
    { name: "Teknoted_addUsers", component : Teknoted_addUsers,  path : '/teknoted_addUsers' },
    { name: "Teknoted_addQuestions", component : Teknoted_addQuestions,  path : '/teknoted_addQuestions' },
    { name: "Teknoted_test", component : Teknoted_test,  path : '/teknoted_test' },





    { name: "deloitte", component : S3_Login, path : '/', meta: {company: 'deloitte'}},
    { name: "deloitte", component : S3_Login, path : '/deloitte', meta: {company: 'deloitte'}},
    { name: "deloitte_Lobby", component : S3_Lobby, path : '/deloitte/lobby', meta: {company: 'deloitte'}},
    { name: "deloitte_Takim", component : S3_Takim, path : '/deloitte/takim', meta: {company: 'deloitte'}},
    { name: "deloitte_Game", component : S3_Game, path : '/deloitte/game', meta: {company: 'deloitte'}},
    { name: "deloitte_Master", component : S3_MasterPlay, path : '/deloitte/master', meta: {company: 'deloitte'}},
    { name: "deloitte_Final", component : S3_Final, path : '/deloitte/final', meta: {company: 'deloitte'}},
    { name: "deloitte_Sunucu", component : S3_Sunucu, path : '/deloitte/sunucu', meta: {company: 'deloitte'}},
    { name: "deloitte_SunucuFinal", component : S3_SunucuFinal, path : '/deloitte/finalsunucu', meta: {company: 'deloitte'}},
    { name: "deloitte_Ekran", component : S3_Ekran, path : '/deloitte/ekran', meta: {company: 'deloitte'}},
    { name: "deloitte_Rapor", component : S3_Rapor, path : '/deloitte/rapor', meta: {company: 'deloitte'}},


];

const router = new VueRouter({

    mode: 'history',
    base: '',
    routes
    
});


//const loginRoute = '/login';


    /*
router.beforeEach(async (to, from, next) => {

    const user_group = store.getters.getUserGroup;


    if( to.meta.permission != "all" && user_group != to.meta.permission){
        next({ path: loginRoute });
    }else{
        next();
    }
    

  
    
    
});
  */


export default router;

