import Vuex from 'vuex'
import Vue from 'vue'
import createPersistedState from 'vuex-persistedstate'

//import Auth from './modules/auth'

Vue.use(Vuex);

const getters = {
    getLanguage: state => {
        return state.language
    },
    getUid: state => {
        return state.uid
    },
    getIsAdmin: state => {
        return state.isAdmin
    },
    getName: state => {
        return state.name
    },
    getEmail: state => {
        return state.email
    },
    get_q1_answered: state => { return state.q1_answered; },
    get_q2_answered: state => { return state.q2_answered; },
    get_q3_answered: state => { return state.q3_answered; },
    get_q4_answered: state => { return state.q4_answered; },
    get_q5_answered: state => { return state.q5_answered; },
    get_q6_answered: state => { return state.q6_answered; },
    get_q7_answered: state => { return state.q7_answered; },
    get_q8_answered: state => { return state.q8_answered; },
    get_q9_answered: state => { return state.q9_answered; },
    get_q10_answered: state => { return state.q10_answered; },
    get_q11_answered: state => { return state.q11_answered; },
    get_q12_answered: state => { return state.q12_answered; },
    get_q13_answered: state => { return state.q13_answered; },
    get_q14_answered: state => { return state.q14_answered; },
    get_q15_answered: state => { return state.q15_answered; },
    get_q16_answered: state => { return state.q16_answered; },
    get_q17_answered: state => { return state.q17_answered; },
    get_q18_answered: state => { return state.q18_answered; },
    get_q19_answered: state => { return state.q19_answered; },
    get_q20_answered: state => { return state.q20_answered; },
    get_q21_answered: state => { return state.q21_answered; },
    get_q22_answered: state => { return state.q22_answered; },
    get_q23_answered: state => { return state.q23_answered; },
    get_q24_answered: state => { return state.q24_answered; },
    get_q25_answered: state => { return state.q25_answered; },
    get_q26_answered: state => { return state.q26_answered; },
    get_q27_answered: state => { return state.q27_answered; },
    get_q28_answered: state => { return state.q28_answered; },
    get_q29_answered: state => { return state.q29_answered; },
    get_q30_answered: state => { return state.q30_answered; },
    get_q31_answered: state => { return state.q31_answered; },
    get_q32_answered: state => { return state.q32_answered; },
    get_q33_answered: state => { return state.q33_answered; },
    get_q34_answered: state => { return state.q34_answered; },
    get_q35_answered: state => { return state.q35_answered; },
    get_q36_answered: state => { return state.q36_answered; },

    get_q1_time: state => { return state.q1_time; },
    get_q2_time: state => { return state.q2_time; },
    get_q3_time: state => { return state.q3_time; },
    get_q4_time: state => { return state.q4_time; },
    get_q5_time: state => { return state.q5_time; },
    get_q6_time: state => { return state.q6_time; },
    get_q7_time: state => { return state.q7_time; },
    get_q8_time: state => { return state.q8_time; },
    get_q9_time: state => { return state.q9_time; },
    get_q10_time: state => { return state.q10_time; },
    get_q11_time: state => { return state.q11_time; },
    get_q12_time: state => { return state.q12_time; },
    get_q13_time: state => { return state.q13_time; },
    get_q14_time: state => { return state.q14_time; },
    get_q15_time: state => { return state.q15_time; },
    get_q16_time: state => { return state.q16_time; },
    get_q17_time: state => { return state.q17_time; },
    get_q18_time: state => { return state.q18_time; },
    get_q19_time: state => { return state.q19_time; },
    get_q20_time: state => { return state.q20_time; },
    get_q21_time: state => { return state.q21_time; },
    get_q22_time: state => { return state.q22_time; },
    get_q23_time: state => { return state.q23_time; },
    get_q24_time: state => { return state.q24_time; },
    get_q25_time: state => { return state.q25_time; },
    get_q26_time: state => { return state.q26_time; },
    get_q27_time: state => { return state.q27_time; },
    get_q28_time: state => { return state.q28_time; },
    get_q29_time: state => { return state.q29_time; },
    get_q30_time: state => { return state.q30_time; },
    get_q31_time: state => { return state.q31_time; },
    get_q32_time: state => { return state.q32_time; },
    get_q33_time: state => { return state.q33_time; },
    get_q34_time: state => { return state.q34_time; },
    get_q35_time: state => { return state.q35_time; },
    get_q36_time: state => { return state.q36_time; },
    
    get_runningQuestion: state => { return state.runningQuestion; }

    
    
};
const actions = {};
const mutations = {
    setLanguage (state, data) {
        state.language = data;
    },
    setUid (state, data) {
        state.uid = data;
    },
    setIsAdmin (state, data) {
        state.isAdmin = data;
    },
    setName (state, data) {
        state.name = data;
    },
    setEmail (state, data) {
        state.email = data;
    },
    set_q1_answered (state, data) { state.q1_answered = data;},
    set_q2_answered (state, data) { state.q2_answered = data;},
    set_q3_answered (state, data) { state.q3_answered = data;},
    set_q4_answered (state, data) { state.q4_answered = data;},
    set_q5_answered (state, data) { state.q5_answered = data;},
    set_q6_answered (state, data) { state.q6_answered = data;},
    set_q7_answered (state, data) { state.q7_answered = data;},
    set_q8_answered (state, data) { state.q8_answered = data;},
    set_q9_answered (state, data) { state.q9_answered = data;},
    set_q10_answered (state, data) { state.q10_answered = data;},
    set_q11_answered (state, data) { state.q11_answered = data;},
    set_q12_answered (state, data) { state.q12_answered = data;},
    set_q13_answered (state, data) { state.q13_answered = data;},
    set_q14_answered (state, data) { state.q14_answered = data;},
    set_q15_answered (state, data) { state.q15_answered = data;},
    set_q16_answered (state, data) { state.q16_answered = data;},
    set_q17_answered (state, data) { state.q17_answered = data;},
    set_q18_answered (state, data) { state.q18_answered = data;},
    set_q19_answered (state, data) { state.q19_answered = data;},
    set_q20_answered (state, data) { state.q20_answered = data;},
    set_q21_answered (state, data) { state.q21_answered = data;},
    set_q22_answered (state, data) { state.q22_answered = data;},
    set_q23_answered (state, data) { state.q23_answered = data;},
    set_q24_answered (state, data) { state.q24_answered = data;},
    set_q25_answered (state, data) { state.q25_answered = data;},
    set_q26_answered (state, data) { state.q26_answered = data;},
    set_q27_answered (state, data) { state.q27_answered = data;},
    set_q28_answered (state, data) { state.q28_answered = data;},
    set_q29_answered (state, data) { state.q29_answered = data;},
    set_q30_answered (state, data) { state.q30_answered = data;},
    set_q31_answered (state, data) { state.q31_answered = data;},
    set_q32_answered (state, data) { state.q32_answered = data;},
    set_q33_answered (state, data) { state.q33_answered = data;},
    set_q34_answered (state, data) { state.q34_answered = data;},
    set_q35_answered (state, data) { state.q35_answered = data;},
    set_q36_answered (state, data) { state.q36_answered = data;},



    set_q1_time (state, data) { state.q1_time = data;},
    set_q2_time (state, data) { state.q2_time = data;},
    set_q3_time (state, data) { state.q3_time = data;},
    set_q4_time (state, data) { state.q4_time = data;},
    set_q5_time (state, data) { state.q5_time = data;},
    set_q6_time (state, data) { state.q6_time = data;},
    set_q7_time (state, data) { state.q7_time = data;},
    set_q8_time (state, data) { state.q8_time = data;},
    set_q9_time (state, data) { state.q9_time = data;},
    set_q10_time (state, data) { state.q10_time = data;},
    set_q11_time (state, data) { state.q11_time = data;},
    set_q12_time (state, data) { state.q12_time = data;},
    set_q13_time (state, data) { state.q13_time = data;},
    set_q14_time (state, data) { state.q14_time = data;},
    set_q15_time (state, data) { state.q15_time = data;},
    set_q16_time (state, data) { state.q16_time = data;},
    set_q17_time (state, data) { state.q17_time = data;},
    set_q18_time (state, data) { state.q18_time = data;},
    set_q19_time (state, data) { state.q19_time = data;},
    set_q20_time (state, data) { state.q20_time = data;},
    set_q21_time (state, data) { state.q21_time = data;},
    set_q22_time (state, data) { state.q22_time = data;},
    set_q23_time (state, data) { state.q23_time = data;},
    set_q24_time (state, data) { state.q24_time = data;},
    set_q25_time (state, data) { state.q25_time = data;},
    set_q26_time (state, data) { state.q26_time = data;},
    set_q27_time (state, data) { state.q27_time = data;},
    set_q28_time (state, data) { state.q28_time = data;},
    set_q29_time (state, data) { state.q29_time = data;},
    set_q30_time (state, data) { state.q30_time = data;},
    set_q31_time (state, data) { state.q31_time = data;},
    set_q32_time (state, data) { state.q32_time = data;},
    set_q33_time (state, data) { state.q33_time = data;},
    set_q34_time (state, data) { state.q34_time = data;},
    set_q35_time (state, data) { state.q35_time = data;},
    set_q36_time (state, data) { state.q36_time = data;},
    



    set_runningQuestion (state, data) { state.runningQuestion = data;},




};

export const store = new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        language : "tr",
        uid : "",
        name : "",
        email : "",
        q1_answered : 0,
        q2_answered : 0,
        q3_answered : 0,
        q4_answered : 0,
        q5_answered : 0,
        q6_answered : 0,
        q7_answered : 0,
        q8_answered : 0,
        q9_answered : 0,
        q10_answered : 0,
        q11_answered : 0,
        q12_answered : 0,
        q13_answered : 0,
        q14_answered : 0,
        q15_answered : 0,
        q16_answered : 0,
        q17_answered : 0,
        q18_answered : 0,
        q19_answered : 0,
        q20_answered : 0,
        q21_answered : 0,
        q22_answered : 0,
        q23_answered : 0,
        q24_answered : 0,
        q25_answered : 0,
        q26_answered : 0,
        q27_answered : 0,
        q28_answered : 0,
        q29_answered : 0,
        q30_answered : 0,
        q31_answered : 0,
        q32_answered : 0,
        q33_answered : 0,
        q34_answered : 0,
        q35_answered : 0,
        q36_answered : 0,

        q1_time:0,
        q2_time:0,
        q3_time:0,
        q4_time:0,
        q5_time:0,
        q6_time:0,
        q7_time:0,
        q8_time:0,
        q9_time:0,
        q10_time:0,
        q11_time:0,
        q12_time:0,
        q13_time:0,
        q14_time:0,
        q15_time:0,
        q16_time:0,
        q17_time:0,
        q18_time:0,
        q19_time:0,
        q20_time:0,
        q21_time:0,
        q22_time:0,
        q23_time:0,
        q24_time:0,
        q25_time:0,
        q26_time:0,
        q27_time:0,
        q28_time:0,
        q29_time:0,
        q30_time:0,
        q31_time:0,
        q32_time:0,
        q33_time:0,
        q34_time:0,
        q35_time:0,
        q36_time:0,

        runningQuestion : 0
        
    },
    actions,
    getters,
    mutations,
});

export default store;

