<template>
    <div class="container text-white mt-2">

        <div class="row">

            <div class="col-1 "><div class="p-2 text-center text-white takim_kirmizi">{{kirmizi}}</div></div>
            <div class="col-1 "><div class="p-2 text-center text-white takim_mavi">{{mavi}}</div></div>
            <div class="col-1 "><div class="p-2 text-center text-white takim_sari">{{sari}}</div></div>
            <div class="col-1 "><div class="p-2 text-center text-white takim_mor">{{mor}}</div></div>
            <div class="col-1 "><div class="p-2 text-center text-white takim_turuncu">{{turuncu}}</div></div>
            <div class="col-1 "><div class="p-2 text-center text-white takim_yesil">{{yesil}}</div></div>

            <div class="col-12 mb-4 mt-5">
                
                <div class="d-flex ">

                    <div class="flex-fill">
                        <a href="#" v-if="status == 0" @click="acGame" class="btn btn-dark btn-sm ">Oyun Kapalı</a>
                        <a href="#" v-if="status == 1" @click="kapatGame" class="btn btn-warning btn-sm ">Oyun Açık</a>
                    </div>

                    <div class="flex-fill">
                        <a href="#" v-if="ekran_puanlar == 0" @click="acEkranPuan" class="btn btn-dark btn-sm ">Ekran - Anlık Durumu Göster</a>
                        <a href="#" v-if="ekran_puanlar == 1" @click="kapatEkranPuan" class="btn btn-success btn-sm ">Ekran - Anlık Durumu Gizle</a>
                    </div>

                    <div class="flex-fill">
                        <a href="#" @click="say" class="btn btn-dark btn-sm float-right  ml-3">Say</a>
                        <span class="ml-3 float-right color_ok">Giriş Yapan: {{toplamGiren}}</span>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <b-progress v-if="running == 1" :value="timeRemaining" :max="15" variant="dark"></b-progress>
            </div>

            

            <div class="col-12 text-white " v-for="(q,index) in questions" :key="q.questionId">
                <div class="row border p-2 rounded m-1">

                    <div class="col-10 p-2 color_ok " >
                        <div>{{index +1}} - {{q.question}}</div>


                        <div class="mt-4" v-if="openedSoru <= (index+1) && openedSoru > index ">
                            <a v-if="q.kirmizi == 0"  @click="hesapQuestion(q.questionId,index)" class="btn btn-sm btn-dark">Hesapla</a>

                            Kirmizi: {{q.kirmizi}}  Mavi: {{q.mavi}}  Sari: {{q.sari}}  Mor: {{q.mor}}  Turuncu: {{q.turuncu}}  Yesil: {{q.yesil}}
                        </div>
                        


                    </div>
                    <div class="col-2 text-right p-2">
                        <a  v-if="openedSoru <= (index) " @click="startQuestion(q.questionId,index)" class="btn btn-success">Başlat</a>
                    </div>
                </div>
            </div>


        </div>


        <div class="my-5 text-center">
            <a href="#"  @click="finishGame" class="btn btn-danger btn-sm mr-5">Oyunu Bitir</a>
            <a href="#" v-if="company == 'deloitte'" @click="reset" class="btn btn-sm btn-primary">RESET</a>
        </div>

    </div>
</template>

<style >
@import '~@/styles/master.css';
</style>


<script>


import { initializeApp } from "firebase/app";
import { getDatabase,ref,set,onValue,serverTimestamp,get,child  } from "firebase/database";

//import { getDatabase,ref,set,child,onValue,get,serverTimestamp } from "firebase/database";

const config = {
    apiKey: "AIzaSyDNmy0ZlEDGtMBNtXbTdxBNEi4bPklV1SQ",
    authDomain: "quizylive.firebaseapp.com",
    databaseURL: "https://quizylive-default-rtdb.firebaseio.com",
    projectId: "quizylive",
    storageBucket: "quizylive.appspot.com",
    messagingSenderId: "497024495958",
    appId: "1:497024495958:web:e79a3a7a61d0c313a0e6f7"
};

const f_app = initializeApp(config);
const db = getDatabase(f_app);
const dbRef = ref(db);

export default {

    data(){
        return {
            company: "deloitte",
            secret: "",
            status: 0,
            questions: [],
            timeStarted : 0,
            timeRemaining : 15,
            running:0,
            openedSoru:0,
            toplamGiren:0,
            ekran_puanlar:0,
            winners:[],
            winners_temp:[],

            kirmizi:0,
            mavi:0,
            sari:0,
            mor:0,
            turuncu:0,
            yesil:0


        }
    },

    methods:{
        hesapQuestion (questionId,index) {

            if(this.running == 1){
                this.showToast("Şu an hesaplayamazsınız. Sürenin dolmasını bekleyin!");
            }else{

                console.log(questionId);
                console.log(index);

                let asilId = index +1

                let _kirmizi = 0;
                let _mavi = 0;
                let _sari = 0;
                let _mor = 0;
                let _turuncu = 0;
                let _yesil = 0;

                let sec1 = 0;
                let sec2 = 0;
                let sec3 = 0;
                let sec4 = 0;

                get(child(dbRef, "deloitte/cevaplar/")).then((snapshot) => {

                    snapshot.forEach(e => {

                        if(e.val().dogru == 1){
                            if(e.val().takim == "kirmizi"){_kirmizi++}
                            if(e.val().takim == "mavi"){_mavi++}
                            if(e.val().takim == "sari"){_sari++}
                            if(e.val().takim == "mor"){_mor++}
                            if(e.val().takim == "turuncu"){_turuncu++}
                            if(e.val().takim == "yesil"){_yesil++}
                        }

                        if(e.val().index == "0"){sec1++}
                        if(e.val().index == "1"){sec2++}
                        if(e.val().index == "2"){sec3++}
                        if(e.val().index == "3"){sec4++}

                    }); 

                    
                }).then(()=>{


                    //sorunun içine kayıt
                    set(ref(db, "deloitte/questions/" + asilId +  "/mavi"), _mavi); 
                    set(ref(db, "deloitte/questions/" + asilId +  "/sari"), _sari); 
                    set(ref(db, "deloitte/questions/" + asilId +  "/mor"), _mor); 
                    set(ref(db, "deloitte/questions/" + asilId +  "/turuncu"), _turuncu); 
                    set(ref(db, "deloitte/questions/" + asilId +  "/yesil"), _yesil); 
                    set(ref(db, "deloitte/questions/" + asilId +  "/kirmizi"), _kirmizi); 
                    

                    set(ref(db, "mercan/questions/" + asilId +  "/secenekler/0/secildi"), sec1); 
                    set(ref(db, "mercan/questions/" + asilId +  "/secenekler/1/secildi"), sec2); 
                    set(ref(db, "mercan/questions/" + asilId +  "/secenekler/2/secildi"), sec3); 
                    set(ref(db, "mercan/questions/" + asilId +  "/secenekler/3/secildi"), sec4); 


                }).then(() => {
                    
                    //birinciyi bul

                    let arr = [_kirmizi, _mavi, _sari, _mor, _turuncu, _yesil]; 


                    let max = Math.max(...arr);

                    console.log(max);

                    let birinciler = ""
                    
                    
                    if(_kirmizi == max){
                        
                        birinciler = birinciler + "kirmizi,"
                        set(ref(db, "deloitte/takim_kirmizi"), this.kirmizi+1);
                    }

                    if(_mavi == max){
                        birinciler = birinciler + "mavi,"
                        set(ref(db, "deloitte/takim_mavi"), this.mavi+1);
                    }

                    if(_sari == max){
                        birinciler = birinciler + "sari,"
                        set(ref(db, "deloitte/takim_sari"), this.sari+1);
                    }

                    if(_mor == max){
                        birinciler = birinciler + "mor,"
                        set(ref(db, "deloitte/takim_mor"), this.mor+1);
                    }

                    if(_turuncu == max){
                        birinciler = birinciler + "turuncu,"
                        set(ref(db, "deloitte/takim_turuncu"), this.turuncu+1);
                    }

                    if(_yesil == max){
                        birinciler = birinciler + "yesil,"
                        set(ref(db, "deloitte/takim_yesil"), this.yesil+1);
                    }

                    set(ref(db, "deloitte/ekran_birinci"),  birinciler);

                    
                });


                

                //ekran dogru
                set(ref(db, "deloitte/ekran_dogru_secenek"), 1);  //ekle

            }

        },  

        say() {
            var sasa = 0;
            let nRef = "deloitte/users";
            get(child(dbRef, nRef)).then((snapshot) => {
                
                snapshot.forEach(e => {

                    if(e.val().active == 1){
                        sasa += 1;
                    }

                    
                }); 

                
            }).then(()=>{

                set(ref(db, "deloitte/toplamGiren"), sasa); 

            }).catch((error) => {
                console.error(error);
            });
        },
        acGame(){
            set(ref(db, "deloitte/status"), 1);
        },
        kapatGame(){
            set(ref(db, "deloitte/status"), 0);
        },
        acEkranPuan(){

            console.log("e");

            set(ref(db, "deloitte/ekran_puanlar"), 1);

            



            
        },
        kapatEkranPuan(){
            set(ref(db, "deloitte/ekran_puanlar"), 0);
            set(ref(db, "deloitte/winners_temp"), null);
        },


        startQuestion(questionId,index){

            if(this.running == 1){
                this.showToast("Şu an soru başlatamazsınız. Sürenin dolmasını bekleyin!");
            }else{

                set(ref(db, "deloitte/ekran_dogru_secenek"), 0); //ekle
                set(ref(db, "deloitte/ekran_birinci"), ""); //ekle
                set(ref(db, "deloitte/cevaplar"), null); //ekle

                this.questions.forEach(element => {
                        
                    if(element.questionId == questionId){


                        let nRef_user = "deloitte/users";
                        get(child(dbRef, nRef_user)).then((snapshot) => {

                            snapshot.forEach(e => {

                                console.log(e.key);
                                set(ref(db, "deloitte/users/"+e.key+"/puankazan"), 0);

                            }); 
                            
                        });




                        element.opened = 1;
                        let nin = index+1;
                        set(ref(db, this.company+"/questions/"+nin+"/opened"), 1);
                        set(ref(db, this.company+"/questions/"+nin+"/dateBasla"), serverTimestamp());
                        set(ref(db, this.company+"/openedSoru"), nin);





                        this.$store.commit('set_runningQuestion', nin);

                        if(nin == 1){this.$store.commit('set_q1_time', this.findNow());}
                        if(nin == 2){this.$store.commit('set_q2_time', this.findNow());}
                        if(nin == 3){this.$store.commit('set_q3_time', this.findNow());}
                        if(nin == 4){this.$store.commit('set_q4_time', this.findNow());}
                        if(nin == 5){this.$store.commit('set_q5_time', this.findNow());}
                        if(nin == 6){this.$store.commit('set_q6_time', this.findNow());}
                        if(nin == 7){this.$store.commit('set_q7_time', this.findNow());}
                        if(nin == 8){this.$store.commit('set_q8_time', this.findNow());}
                        if(nin == 9){this.$store.commit('set_q9_time', this.findNow());}
                        if(nin == 10){this.$store.commit('set_q10_time', this.findNow());}
                        if(nin == 11){this.$store.commit('set_q11_time', this.findNow());}
                        if(nin == 12){this.$store.commit('set_q12_time', this.findNow());}
                        if(nin == 13){this.$store.commit('set_q13_time', this.findNow());}
                        if(nin == 14){this.$store.commit('set_q14_time', this.findNow());}
                        if(nin == 15){this.$store.commit('set_q15_time', this.findNow());}
                        if(nin == 16){this.$store.commit('set_q16_time', this.findNow());}
                        if(nin == 17){this.$store.commit('set_q17_time', this.findNow());}
                        if(nin == 18){this.$store.commit('set_q18_time', this.findNow());}
                        if(nin == 19){this.$store.commit('set_q19_time', this.findNow());}
                        if(nin == 20){this.$store.commit('set_q20_time', this.findNow());}


                        this.running = 1;
                        this.runProgress();
                    }

                });
            }
        },
        findClass(){

            let cls = "btn btn-success";
            

            return cls
        },

        runProgress(){

            if(this.running == 1){

                let tt_time =  this.findQuestionTime();

                let gecen = this.findNow() - tt_time;
                let kalan = 15-gecen;
                this.timeRemaining = kalan;
                if(kalan <= 0){this.running = 0;}else{
                    setTimeout(() => this.runProgress(), 1000);
                }
            }

        },

        findQuestionTime(){

            let runningQuestion =  this.$store.getters.get_runningQuestion;
            let ttime = 0;
            if(runningQuestion == 1){  ttime = this.$store.getters.get_q1_time; }
            if(runningQuestion == 2){  ttime = this.$store.getters.get_q2_time; }
            if(runningQuestion == 3){  ttime = this.$store.getters.get_q3_time; }
            if(runningQuestion == 4){  ttime = this.$store.getters.get_q4_time; }
            if(runningQuestion == 5){  ttime = this.$store.getters.get_q5_time; }
            if(runningQuestion == 6){  ttime = this.$store.getters.get_q6_time; }
            if(runningQuestion == 7){  ttime = this.$store.getters.get_q7_time; }
            if(runningQuestion == 8){  ttime = this.$store.getters.get_q8_time; }
            if(runningQuestion == 9){  ttime = this.$store.getters.get_q9_time; }
            if(runningQuestion == 10){  ttime = this.$store.getters.get_q10_time; }
            if(runningQuestion == 11){  ttime = this.$store.getters.get_q11_time; }
            if(runningQuestion == 12){  ttime = this.$store.getters.get_q12_time; }
            if(runningQuestion == 13){  ttime = this.$store.getters.get_q13_time; }
            if(runningQuestion == 14){  ttime = this.$store.getters.get_q14_time; }
            if(runningQuestion == 15){  ttime = this.$store.getters.get_q15_time; }
            if(runningQuestion == 16){  ttime = this.$store.getters.get_q16_time; }
            if(runningQuestion == 17){  ttime = this.$store.getters.get_q17_time; }
            if(runningQuestion == 18){  ttime = this.$store.getters.get_q18_time; }
            if(runningQuestion == 19){  ttime = this.$store.getters.get_q19_time; }
            if(runningQuestion == 20){  ttime = this.$store.getters.get_q20_time; }



            return ttime;
        },

        afterRefesh(){
            let runningQuestion =  this.$store.getters.get_runningQuestion;
            let ttime = 0;
            if(runningQuestion == 1){  ttime = this.$store.getters.get_q1_time; }
            if(runningQuestion == 2){  ttime = this.$store.getters.get_q2_time; }
            if(runningQuestion == 3){  ttime = this.$store.getters.get_q3_time; }
            if(runningQuestion == 4){  ttime = this.$store.getters.get_q4_time; }
            if(runningQuestion == 5){  ttime = this.$store.getters.get_q5_time; }
            if(runningQuestion == 6){  ttime = this.$store.getters.get_q6_time; }
            if(runningQuestion == 7){  ttime = this.$store.getters.get_q7_time; }
            if(runningQuestion == 8){  ttime = this.$store.getters.get_q8_time; }
            if(runningQuestion == 9){  ttime = this.$store.getters.get_q9_time; }
            if(runningQuestion == 10){  ttime = this.$store.getters.get_q10_time; }
            if(runningQuestion == 11){  ttime = this.$store.getters.get_q11_time; }
            if(runningQuestion == 12){  ttime = this.$store.getters.get_q12_time; }
            if(runningQuestion == 13){  ttime = this.$store.getters.get_q13_time; }
            if(runningQuestion == 14){  ttime = this.$store.getters.get_q14_time; }
            if(runningQuestion == 15){  ttime = this.$store.getters.get_q15_time; }
            if(runningQuestion == 16){  ttime = this.$store.getters.get_q16_time; }
            if(runningQuestion == 17){  ttime = this.$store.getters.get_q17_time; }
            if(runningQuestion == 18){  ttime = this.$store.getters.get_q18_time; }
            if(runningQuestion == 19){  ttime = this.$store.getters.get_q19_time; }
            if(runningQuestion == 20){  ttime = this.$store.getters.get_q20_time; }


            let nnow_20 = this.findNow() - 15;

            if(ttime > nnow_20){
                this.running = 1;
                this.runProgress();
            }

            console.log("running:"+this.running);
            console.log("runningQuestion:"+runningQuestion);
            console.log("ttime:"+ttime);

        },

        reset(){

            if(confirm("Oyunu sıfırlamak istediğinize emin misiniz?")){

                set(ref(db, this.company+"/questions/1/opened"), 0);
                set(ref(db, this.company+"/questions/2/opened"), 0);
                set(ref(db, this.company+"/questions/3/opened"), 0);
                set(ref(db, this.company+"/questions/4/opened"), 0);
                set(ref(db, this.company+"/questions/5/opened"), 0);
                set(ref(db, this.company+"/questions/6/opened"), 0);
                set(ref(db, this.company+"/questions/7/opened"), 0);
                set(ref(db, this.company+"/questions/8/opened"), 0);
                set(ref(db, this.company+"/questions/9/opened"), 0);
                set(ref(db, this.company+"/questions/10/opened"), 0);





                


                set(ref(db, this.company+"/questions/1/kirmizi"), 0);
                set(ref(db, this.company+"/questions/1/sari"), 0);
                set(ref(db, this.company+"/questions/1/mavi"), 0);
                set(ref(db, this.company+"/questions/1/mor"),0);
                set(ref(db, this.company+"/questions/1/turuncu"), 0);
                set(ref(db, this.company+"/questions/1/yesil"), 0);


                set(ref(db, this.company+"/questions/2/kirmizi"), 0);
                set(ref(db, this.company+"/questions/2/sari"), 0);
                set(ref(db, this.company+"/questions/2/mavi"), 0);
                set(ref(db, this.company+"/questions/2/mor"),0);
                set(ref(db, this.company+"/questions/2/turuncu"), 0);
                set(ref(db, this.company+"/questions/2/yesil"), 0);



                set(ref(db, this.company+"/questions/3/kirmizi"), 0);
                set(ref(db, this.company+"/questions/3/sari"), 0);
                set(ref(db, this.company+"/questions/3/mavi"), 0);
                set(ref(db, this.company+"/questions/3/mor"),0);
                set(ref(db, this.company+"/questions/3/turuncu"), 0);
                set(ref(db, this.company+"/questions/3/yesil"), 0);

                
                set(ref(db, this.company+"/questions/4/kirmizi"), 0);
                set(ref(db, this.company+"/questions/4/sari"), 0);
                set(ref(db, this.company+"/questions/4/mavi"), 0);
                set(ref(db, this.company+"/questions/4/mor"),0);
                set(ref(db, this.company+"/questions/4/turuncu"), 0);
                set(ref(db, this.company+"/questions/4/yesil"), 0);



                set(ref(db, this.company+"/questions/5/kirmizi"), 0);
                set(ref(db, this.company+"/questions/5/sari"), 0);
                set(ref(db, this.company+"/questions/5/mavi"), 0);
                set(ref(db, this.company+"/questions/5/mor"),0);
                set(ref(db, this.company+"/questions/5/turuncu"), 0);
                set(ref(db, this.company+"/questions/5/yesil"), 0);


                set(ref(db, this.company+"/questions/6/kirmizi"), 0);
                set(ref(db, this.company+"/questions/6/sari"), 0);
                set(ref(db, this.company+"/questions/6/mavi"), 0);
                set(ref(db, this.company+"/questions/6/mor"),0);
                set(ref(db, this.company+"/questions/6/turuncu"), 0);
                set(ref(db, this.company+"/questions/6/yesil"), 0);



                set(ref(db, this.company+"/questions/7/kirmizi"), 0);
                set(ref(db, this.company+"/questions/7/sari"), 0);
                set(ref(db, this.company+"/questions/7/mavi"), 0);
                set(ref(db, this.company+"/questions/7/mor"),0);
                set(ref(db, this.company+"/questions/7/turuncu"), 0);
                set(ref(db, this.company+"/questions/7/yesil"), 0);


                set(ref(db, this.company+"/questions/8/kirmizi"), 0);
                set(ref(db, this.company+"/questions/8/sari"), 0);
                set(ref(db, this.company+"/questions/8/mavi"), 0);
                set(ref(db, this.company+"/questions/8/mor"),0);
                set(ref(db, this.company+"/questions/8/turuncu"), 0);
                set(ref(db, this.company+"/questions/8/yesil"), 0);


                set(ref(db, this.company+"/questions/9/kirmizi"), 0);
                set(ref(db, this.company+"/questions/9/sari"), 0);
                set(ref(db, this.company+"/questions/9/mavi"), 0);
                set(ref(db, this.company+"/questions/9/mor"),0);
                set(ref(db, this.company+"/questions/9/turuncu"), 0);
                set(ref(db, this.company+"/questions/9/yesil"), 0);


                set(ref(db, this.company+"/questions/10/kirmizi"), 0);
                set(ref(db, this.company+"/questions/10/sari"), 0);
                set(ref(db, this.company+"/questions/10/mavi"), 0);
                set(ref(db, this.company+"/questions/10/mor"),0);
                set(ref(db, this.company+"/questions/10/turuncu"), 0);
                set(ref(db, this.company+"/questions/10/yesil"), 0);


                
                set(ref(db, this.company+"/cevaplar"), null);
                set(ref(db, this.company+"/ekran_birinci"), "");




                set(ref(db, this.company+"/questions/1/secenekler/0/secildi"), 0);
                set(ref(db, this.company+"/questions/1/secenekler/1/secildi"), 0);
                set(ref(db, this.company+"/questions/1/secenekler/2/secildi"), 0);
                set(ref(db, this.company+"/questions/1/secenekler/3/secildi"), 0);

                set(ref(db, this.company+"/questions/2/secenekler/0/secildi"), 0);
                set(ref(db, this.company+"/questions/2/secenekler/1/secildi"), 0);
                set(ref(db, this.company+"/questions/2/secenekler/2/secildi"), 0);
                set(ref(db, this.company+"/questions/2/secenekler/3/secildi"), 0);

                set(ref(db, this.company+"/questions/3/secenekler/0/secildi"), 0);
                set(ref(db, this.company+"/questions/3/secenekler/1/secildi"), 0);
                set(ref(db, this.company+"/questions/3/secenekler/2/secildi"), 0);
                set(ref(db, this.company+"/questions/3/secenekler/3/secildi"), 0);

                set(ref(db, this.company+"/questions/4/secenekler/0/secildi"), 0);
                set(ref(db, this.company+"/questions/4/secenekler/1/secildi"), 0);
                set(ref(db, this.company+"/questions/4/secenekler/2/secildi"), 0);
                set(ref(db, this.company+"/questions/4/secenekler/3/secildi"), 0);

                set(ref(db, this.company+"/questions/5/secenekler/0/secildi"), 0);
                set(ref(db, this.company+"/questions/5/secenekler/1/secildi"), 0);
                set(ref(db, this.company+"/questions/5/secenekler/2/secildi"), 0);
                set(ref(db, this.company+"/questions/5/secenekler/3/secildi"), 0);

                set(ref(db, this.company+"/questions/6/secenekler/0/secildi"), 0);
                set(ref(db, this.company+"/questions/6/secenekler/1/secildi"), 0);
                set(ref(db, this.company+"/questions/6/secenekler/2/secildi"), 0);
                set(ref(db, this.company+"/questions/6/secenekler/3/secildi"), 0);

                set(ref(db, this.company+"/questions/7/secenekler/0/secildi"), 0);
                set(ref(db, this.company+"/questions/7/secenekler/1/secildi"), 0);
                set(ref(db, this.company+"/questions/7/secenekler/2/secildi"), 0);
                set(ref(db, this.company+"/questions/7/secenekler/3/secildi"), 0);

                set(ref(db, this.company+"/questions/8/secenekler/0/secildi"), 0);
                set(ref(db, this.company+"/questions/8/secenekler/1/secildi"), 0);
                set(ref(db, this.company+"/questions/8/secenekler/2/secildi"), 0);
                set(ref(db, this.company+"/questions/8/secenekler/3/secildi"), 0);

                set(ref(db, this.company+"/questions/9/secenekler/0/secildi"), 0);
                set(ref(db, this.company+"/questions/9/secenekler/1/secildi"), 0);
                set(ref(db, this.company+"/questions/9/secenekler/2/secildi"), 0);
                set(ref(db, this.company+"/questions/9/secenekler/3/secildi"), 0);

                set(ref(db, this.company+"/questions/10/secenekler/0/secildi"), 0);
                set(ref(db, this.company+"/questions/10/secenekler/1/secildi"), 0);
                set(ref(db, this.company+"/questions/10/secenekler/2/secildi"), 0);
                set(ref(db, this.company+"/questions/10/secenekler/3/secildi"), 0);

                


                set(ref(db, this.company+"/users"), null);
                set(ref(db, this.company+"/winners"), null);
                set(ref(db, this.company+"/winners_temp"), null);
                set(ref(db, this.company+"/cevaplar"), null);



                set(ref(db, this.company+"/openedSoru"), 0);
                set(ref(db, this.company+"/ekran_puanlar"), 0);
                set(ref(db, this.company+"/ekran_dogru_secenek"), 0);
                set(ref(db, this.company+"/status"), 0);
                set(ref(db, this.company+"/toplamGiren"), 0);
                
                set(ref(db, this.company+"/takim_kirmizi"), 0);
                set(ref(db, this.company+"/takim_mavi"), 0);
                set(ref(db, this.company+"/takim_mor"), 0);
                set(ref(db, this.company+"/takim_yesil"), 0);
                set(ref(db, this.company+"/takim_sari"), 0);
                set(ref(db, this.company+"/takim_turuncu"), 0);



                
                this.$store.commit('set_q1_time', 0);
                this.$store.commit('set_q2_time', 0);
                this.$store.commit('set_q3_time', 0);
                this.$store.commit('set_q4_time', 0);
                this.$store.commit('set_q5_time', 0);
                this.$store.commit('set_q6_time', 0);
                this.$store.commit('set_q7_time', 0);
                this.$store.commit('set_q8_time', 0);
                this.$store.commit('set_q9_time', 0);
                this.$store.commit('set_q10_time', 0);
                this.$store.commit('set_q11_time', 0);
                this.$store.commit('set_q12_time', 0);
                this.$store.commit('set_q13_time', 0);
                this.$store.commit('set_q14_time', 0);
                this.$store.commit('set_q15_time', 0);
                this.$store.commit('set_q16_time', 0);
                this.$store.commit('set_q17_time', 0);
                this.$store.commit('set_q18_time', 0);
                this.$store.commit('set_q19_time', 0);
                this.$store.commit('set_q20_time', 0);



                this.$store.commit('set_runningQuestion', 0);

            }

        },
        orderedItems(items) {
            items.sort((a, b) => {
                return a.times - b.times;
            });  
            return items;
        },
        getCompany(){
            this.company = this.$route.meta.company;
        },
        
        showToast(gelen){
            this.$bvToast.toast(gelen, {
                title: "Hata!",
                autoHideDelay: 3000,
                appendToast: true,
                variant: 'danger',
                solid: true,
            })
        },

        selectItem(number){
            if(!this.selectedNumbers.includes(number)){
                if(confirm("Seçmek istediğinize emin misiniz?")){
                    this.selectedNumbers.push(number);
                    set(ref(db, this.company+"/lastOpen"), number);
                    set(ref(db, this.company+"/opens"), this.selectedNumbers);

                    if(this.awardLastNumberstatus == 1){
                        set(ref(db, this.company+"/awardLastNumber"), number);
                        this.awardLastNumber = number;
                    }

                    if(this.awardGuessNumberstatus == 1){
                        set(ref(db, this.company+"/awardGuessNumber"), number);
                        this.awardGuessNumber = number;
                    }


                }
            }
        },

        itemClass(number){
            let res = "number_box round_box font_medium";
            if(this.selectedNumbers.includes(number)){
                res= "number_box_selected round_box font_medium";
            }
            return res;
        },

        findNow(){
            return Math.round(new Date().getTime()/1000);
        },

        sortAr(a, b){
            
                if ( a.puan < b.puan ){
                    return -1;
                }
                if ( a.puan > b.puan ){
                    return 1;
                }

                return 0;
        },


        finishGame(){
            
            if(this.running == 1){
                this.showToast("Şu an oyunu bitiremezsiniz. Sürenin dolmasını bekleyin!");
            }else{

                if(confirm("Oyunu bitirmek istediğinize emin misiniz?")){

                    //this.winners = [];

                    //const dbRef = ref(getDatabase());

                    set(ref(db, "deloitte/status"), 2);

                }

            }
        },

        winners2Db(){

            console.log("winners2Db");

            console.log(this.winners);

            let i = 0;
            this.winners.forEach(e => {

                if(i<30){
                    set(ref(db, "deloitte/winners/"+i), {
                        name: e.name,
                        email: e.email,
                        puan: e.puan
                    });
                }

                i++;
            });

            
            return i

        },
        winners2Db_temp(){

            console.log(this.winners_temp);

            let i = 0;
            this.winners_temp.forEach(e => {

                if(i<30){
                    set(ref(db, "deloitte/winners_temp/"+i), {
                        name: e.name,
                        email: e.email,
                        puan: e.puan
                    });
                }

                i++;
            });


            set(ref(db, "deloitte/ekran_puanlar"), 1);
            
            return i

        },


    },

    mounted () {
        this.getCompany();
        this.afterRefesh();

        const refOpens = ref(db, "deloitte/questions");
        onValue(refOpens, (snapshot) => {
            const data = snapshot.val();
            if(data != null){
                let ss = [];
                data.forEach(e => {
                    let temp = {
                        questionId:e.questionId,
                        question: e.question,
                        max_puan: e.max_puan,
                        max_user: e.max_user,
                        en_users: e.en_users,
                        opened: e.opened,
                        dateBasla: e.dateBasla,

                        kirmizi: e.kirmizi,
                        yesil: e.yesil,
                        mavi: e.mavi,
                        turuncu: e.turuncu,
                        sari: e.sari,
                        mor: e.mor


                    };
                    ss.push(temp);
                });
                this.questions = ss;
            }
        });

        const refactiveSoru = ref(db, "deloitte/openedSoru");
        onValue(refactiveSoru, (snapshot) => {
            const data = snapshot.val();
            this.openedSoru = data
        });

        const refekran_puanlar = ref(db, "deloitte/ekran_puanlar");
        onValue(refekran_puanlar, (snapshot) => {
            const data = snapshot.val();
            this.ekran_puanlar = data
        });

        const refactivestatus = ref(db, "deloitte/status");
        onValue(refactivestatus, (snapshot) => {
            const data = snapshot.val();
            this.status = data
        });

        const refactiveGiren = ref(db, "deloitte/toplamGiren");
        onValue(refactiveGiren, (snapshot) => {
            this.toplamGiren = snapshot.val()
        });

        const ref_kirmizi = ref(db, "deloitte/takim_kirmizi");
        onValue(ref_kirmizi, (snapshot) => {
            this.kirmizi = snapshot.val()
        });
        const ref_sari = ref(db, "deloitte/takim_sari");
        onValue(ref_sari, (snapshot) => {
            this.sari = snapshot.val()
        });
        const ref_mavi = ref(db, "deloitte/takim_mavi");
        onValue(ref_mavi, (snapshot) => {
            this.mavi = snapshot.val()
        });
        const ref_mor = ref(db, "deloitte/takim_mor");
        onValue(ref_mor, (snapshot) => {
            this.mor = snapshot.val()
        });
        const ref_yesil = ref(db, "deloitte/takim_yesil");
        onValue(ref_yesil, (snapshot) => {
            this.yesil = snapshot.val()
        });
        const ref_turuncu = ref(db, "deloitte/takim_turuncu");
        onValue(ref_turuncu, (snapshot) => {
            this.turuncu = snapshot.val()
        });

    },

    watch: {},


}
</script>