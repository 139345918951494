<template>
    <div class="logo_bg">
        <div class="  ">




            
            <table border="1" >
                <tr>
                    <td>Sicil</td>
                    <td>İsim</td>
                    <td>Toplam Puan</td>
                    <td>{{questions[0].soru}}</td>
                    <td>{{questions[1].soru}}</td>
                    <td>{{questions[2].soru}}</td>
                    <td>{{questions[3].soru}}</td>
                    <td>{{questions[4].soru}}</td>
                    <td>{{questions[5].soru}}</td>
                    <td>{{questions[6].soru}}</td>
                    <td>{{questions[7].soru}}</td>
                    <td>{{questions[8].soru}}</td>
                    <td>{{questions[9].soru}}</td>
                    <td>{{questions[10].soru}}</td>
                    <td>{{questions[11].soru}}</td>
                    <td>{{questions[12].soru}}</td>
                    <td>{{questions[13].soru}}</td>
                    <td>{{questions[14].soru}}</td>
                    <td>{{questions[15].soru}}</td>
                    <td>{{questions[16].soru}}</td>
                    <td>{{questions[17].soru}}</td>
                    <td>{{questions[18].soru}}</td>
                    <td>{{questions[19].soru}}</td>
                </tr>

                <tr v-for="item in computedObj" :key="item.userId" >
                    <td>{{item.email}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.puan}}</td>
                    <td :class="item.soru_1_dogru === 1 ? 'bgbg' : ''">{{item.soru_1.substring(0,20)}}</td>
                    <td :class="item.soru_2_dogru === 1 ? 'bgbg' : ''">{{item.soru_2.substring(0,20)}}</td>
                    <td :class="item.soru_3_dogru === 1 ? 'bgbg' : ''">{{item.soru_3.substring(0,20)}}</td>
                    <td :class="item.soru_4_dogru === 1 ? 'bgbg' : ''">{{item.soru_4.substring(0,20)}}</td>
                    <td :class="item.soru_5_dogru === 1 ? 'bgbg' : ''">{{item.soru_5.substring(0,20)}}</td>
                    <td :class="item.soru_6_dogru === 1 ? 'bgbg' : ''">{{item.soru_6.substring(0,20)}}</td>
                    <td :class="item.soru_7_dogru === 1 ? 'bgbg' : ''">{{item.soru_7.substring(0,20)}}</td>
                    <td :class="item.soru_8_dogru === 1 ? 'bgbg' : ''">{{item.soru_8.substring(0,20)}}</td>
                    <td :class="item.soru_9_dogru === 1 ? 'bgbg' : ''">{{item.soru_9.substring(0,20)}}</td>
                    <td :class="item.soru_10_dogru === 1 ? 'bgbg' : ''">{{item.soru_10.substring(0,20)}}</td>
                    <td :class="item.soru_11_dogru === 1 ? 'bgbg' : ''">{{item.soru_11.substring(0,20)}}</td>
                    <td :class="item.soru_12_dogru === 1 ? 'bgbg' : ''">{{item.soru_12.substring(0,20)}}</td>
                    <td :class="item.soru_13_dogru === 1 ? 'bgbg' : ''">{{item.soru_13.substring(0,20)}}</td>
                    <td :class="item.soru_14_dogru === 1 ? 'bgbg' : ''">{{item.soru_14.substring(0,20)}}</td>
                    <td :class="item.soru_15_dogru === 1 ? 'bgbg' : ''">{{item.soru_15.substring(0,20)}}</td>
                    <td :class="item.soru_16_dogru === 1 ? 'bgbg' : ''">{{item.soru_16.substring(0,20)}}</td>
                    <td :class="item.soru_17_dogru === 1 ? 'bgbg' : ''">{{item.soru_17.substring(0,20)}}</td>
                    <td :class="item.soru_18_dogru === 1 ? 'bgbg' : ''">{{item.soru_18.substring(0,20)}}</td>
                    <td :class="item.soru_19_dogru === 1 ? 'bgbg' : ''">{{item.soru_19.substring(0,20)}}</td>
                    <td :class="item.soru_20_dogru === 1 ? 'bgbg' : ''">{{item.soru_20.substring(0,20)}}</td>
                </tr>

            </table>




        </div>
    </div>
</template>


<style >
  .bgbg {background-color: aqua;}  
</style >

<script>
import { initializeApp } from "firebase/app";
import { getDatabase,ref,get,child } from "firebase/database";
const config = {
    apiKey: "AIzaSyDNmy0ZlEDGtMBNtXbTdxBNEi4bPklV1SQ",
    authDomain: "quizylive.firebaseapp.com",
    databaseURL: "https://quizylive-default-rtdb.firebaseio.com",
    projectId: "quizylive",
    storageBucket: "quizylive.appspot.com",
    messagingSenderId: "497024495958",
    appId: "1:497024495958:web:e79a3a7a61d0c313a0e6f7"
};

const f_app = initializeApp(config);
const db = getDatabase(f_app);
const dbRef = ref(db);
const myCss = (mss) => import('@/styles/'+mss+'.css');

export default {

    data(){
        return {

            language: "en",
            company: "deloittequiz",
            email: '',
            uid: '',
            name: '',
            winners: [ ],
            soru1_cevaplar: [ ],
            soru2_cevaplar: [ ],
            soru3_cevaplar: [ ],
            soru4_cevaplar: [ ],
            soru5_cevaplar: [ ],
            soru6_cevaplar: [ ],
            soru7_cevaplar: [ ],
            soru8_cevaplar: [ ],
            soru9_cevaplar: [ ],
            soru10_cevaplar: [ ],
            soru11_cevaplar: [ ],
            soru12_cevaplar: [ ],
            soru13_cevaplar: [ ],
            soru14_cevaplar: [ ],
            soru15_cevaplar: [ ],
            soru16_cevaplar: [ ],
            soru17_cevaplar: [ ],
            soru18_cevaplar: [ ],
            soru19_cevaplar: [ ],
            soru20_cevaplar: [ ],
            logo: '',
            limit : 200,


questions : [ 
    {
        "soru" : "Aşağıdaki ifadelerden hangisi iş yerinde çalışanı etkileyen fiziksel risk faktörlerinden biri değildir?",
        "secenekler" : [ 
            {
                "dogru" : 0,
                "secenek" : "Gürültü"
            }, 
            {
                "dogru" : 0,
                "secenek" : "Termal konfor"
            }, 
            {
                "dogru" : 0,
                "secenek" : "Toz"
            }, 
            {
                "dogru" : 1,
                "secenek" : "Gaz"
            } 
        ]
        
    },
    {
        "soru" : "Aşağıdakilerden hangisi kimyasalların vücuda giriş yollarından biri değildir?",
        "secenekler" : [ 
            {
                "dogru" : 0,
                "secenek" : "Solunum"
            }, 
            {
                "dogru" : 0,
                "secenek" : "Deri-Cilt"
            }, 
            {
                "dogru" : 0,
                "secenek" : "Sindirim"
            }, 
            {
                "dogru" : 1,
                "secenek" : "Enjeksiyon"
            } 
        ]
        
    },
    {
        "soru" : "Elektrik ile ilgili aşağıdaki ifadelerden hangisi doğrudur?",
        "secenekler" : [ 
            {
                "dogru" : 0,
                "secenek" : "Bölüm Yöneticileri ve Makine Operatörleri küçük elektrik arızalarında duruma müdahale edebilirler."
            }, 
            {
                "dogru" : 0,
                "secenek" : "Çıplak iletkenler civarında çalışırken baret, yalıtkan altlıklı iş ayakkabısı ve iş elbisesi giyilmemelidir."
            }, 
            {
                "dogru" : 0,
                "secenek" : "Yüksek gerilimli tesislerde elektrik çarpmaları genellikle “Kalp Fibrilasyonu” şeklinde olur."
            }, 
            {
                "dogru" : 1,
                "secenek" : "Elektrik akımı geçen canlı vücutlar üzerinde yanık yaraları ve adale krampları oluşturduğundan, kazaya uğrayan kişi elektriğe tutulduğu yeri bırakamaz."
            } 
        ]
        
    },
    {
        "soru" : "İş kazalarının yüzde kaçı önlenebilir?",
        "secenekler" : [ 
            {
                "dogru" : 1,
                "secenek" : "%98"
            }, 
            {
                "dogru" : 0,
                "secenek" : "%50"
            }, 
            {
                "dogru" : 0,
                "secenek" : "%88"
            }, 
            {
                "dogru" : 0,
                "secenek" : "%100"
            } 
        ]
        
    },
    {
        "soru" : "Aşağıdaki ifadelerden hangisi doğrudur?",
        "secenekler" : [ 
            {
                "dogru" : 0,
                "secenek" : "İş yerinde çalışanların sağlık ve güvenlik risklerinden korunmasında en etkin ve en başta yapılması gereken yöntem çalışanlara koruyucu ekipman vermektir."
            }, 
            {
                "dogru" : 0,
                "secenek" : "Kişisel koruyucu donanım seçiminde en önemli husus maliyetlerin uygun olmasıdır."
            }, 
            {
                "dogru" : 0,
                "secenek" : "El kesilme riski olan çalışmalarda minimum kesilme direnci 1 olan eldivenler kullanılmalıdır."
            }, 
            {
                "dogru" : 1,
                "secenek" : "Kişisel koruyucu donanım seçiminde işçinin çalıştığı yerin özellikleri, maruziyet sıklığı ve kullanım süresi dikkate alınmalıdır."
            } 
        ]
        
    },
    {
        "soru" : "Aşağıdaki ifadelerden hangisi yanlıştır?",
        "secenekler" : [ 
            {
                "dogru" : 0,
                "secenek" : "Çalışanların ciddi ve yakın tehlike ile karşı karşıya kaldıkları durumlarda risk giderilene kadar işten kaçınma hakları vardır."
            }, 
            {
                "dogru" : 1,
                "secenek" : "Mesleki risklerin önlenmesi, her türlü tedbirin alınması, gerekli araç ve gereçlerin sağlanması ve mevcut durumun iyileştirilmesi çalışanların görev ve sorumluluğudur."
            }, 
            {
                "dogru" : 0,
                "secenek" : "Çalışanların, işveren tarafından verilen eğitim ve talimatlar doğrultusunda hareket etmeleri gerekir."
            }, 
            {
                "dogru" : 0,
                "secenek" : "Ciddi ve yakın tehlikenin önlenemez olduğu durumlarda çalışanların işten kaçınma hakkı vardır."
            } 
        ]
        
    },
    {
        "soru" : "İş sağlığı ve güvenliğinden kim sorumludur?",
        "secenekler" : [ 
            {
                "dogru" : 0,
                "secenek" : "İşveren"
            }, 
            {
                "dogru" : 0,
                "secenek" : "İş Güvenliği Uzmanı"
            }, 
            {
                "dogru" : 0,
                "secenek" : "Çalışan"
            }, 
            {
                "dogru" : 1,
                "secenek" : "Hepsi"
            } 
        ]
        
    },
    {
        "soru" : "Aşağıdakilerden hangisi makine güvenlik donanımı değildir?",
        "secenekler" : [ 
            {
                "dogru" : 0,
                "secenek" : "Işın Bariyeri"
            }, 
            {
                "dogru" : 0,
                "secenek" : "Acil Stop"
            }, 
            {
                "dogru" : 0,
                "secenek" : "Kapak Switchi"
            }, 
            {
                "dogru" : 1,
                "secenek" : "Elektrik Panosu"
            } 
        ]
        
    },
    {
        "soru" : "Aşağıdakilerden hangisi kişisel koruyucu donanımdır?",
        "secenekler" : [ 
            {
                "dogru" : 1,
                "secenek" : "Cankurtaran yeleği"
            }, 
            {
                "dogru" : 0,
                "secenek" : "Güneş kremi"
            }, 
            {
                "dogru" : 0,
                "secenek" : "Flex"
            }, 
            {
                "dogru" : 0,
                "secenek" : "Seyyar merdiven"
            } 
        ]
        
    },
    {
        "soru" : "Aşağıdakilerden hangisi İş Kanunu Madde 25’e göre işverene haklı fesih sebebi doğurur?",
        "secenekler" : [ 
            {
                "dogru" : 0,
                "secenek" : "Devamsızlığın ardı ardına 3 iş günü veya bir ayda 5 iş gününden fazla sürmesi"
            }, 
            {
                "dogru" : 0,
                "secenek" : "İşçinin işvereni yanıltması, yalan söylemesi"
            }, 
            {
                "dogru" : 0,
                "secenek" : "İşçinin yapmakla görevli olduğu görevleri kendisine hatırlatıldığı halde yapmamakta ısrar etmesi"
            }, 
            {
                "dogru" : 1,
                "secenek" : "Hepsi"
            } 
        ]
        
    },
    {
        "soru" : "Aşağıdakilerden hangisi iş sağlığı ve güvenliğinin amaçlarındandır?",
        "secenekler" : [ 
            {
                "dogru" : 1,
                "secenek" : "Hepsi"
            }, 
            {
                "dogru" : 0,
                "secenek" : "Çalışanın korunması"
            }, 
            {
                "dogru" : 0,
                "secenek" : "İşletmenin korunması"
            }, 
            {
                "dogru" : 0,
                "secenek" : "Üretim ve kalitenin artırılması"
            } 
        ]
        
    },
    {
        "soru" : "deloitte Makina’da 2023 yılında kaç adet iş kazası kayıtlara geçmiştir?",
        "secenekler" : [ 
            {
                "dogru" : 0,
                "secenek" : "20"
            }, 
            {
                "dogru" : 0,
                "secenek" : "21"
            }, 
            {
                "dogru" : 0,
                "secenek" : "22"
            }, 
            {
                "dogru" : 1,
                "secenek" : "23"
            } 
        ]
        
    },
    {
        "soru" : "Aşağıdakilerden hangisi işyerinde var olan risk etmenlerinden biri değildir?",
        "secenekler" : [ 
            {
                "dogru" : 0,
                "secenek" : "Fiziksel risk etmenleri"
            }, 
            {
                "dogru" : 0,
                "secenek" : "Biyolojik risk etmenleri"
            }, 
            {
                "dogru" : 1,
                "secenek" : "Sosyolojik risk etmenleri"
            }, 
            {
                "dogru" : 0,
                "secenek" : "Psikososyal risk etmenleri"
            } 
        ]
        
    },
    {
        "soru" : "Aşağıdakilerden hangisi güvensiz hareket/davranış değildir?",
        "secenekler" : [ 
            {
                "dogru" : 0,
                "secenek" : "Makine güvenlik donanımını kullanılamaz hale getirmek"
            }, 
            {
                "dogru" : 1,
                "secenek" : "Makinelerin periyodik kontrollerinin zamanında yapılmaması"
            }, 
            {
                "dogru" : 0,
                "secenek" : "Çalışma saatleri içerisinde çalışma alanını terketmek"
            }, 
            {
                "dogru" : 0,
                "secenek" : "Kişisel koruyucu malzemeyi kullanmamak"
            } 
        ]
        
    },
    {
        "soru" : "Aşağıdakilerden hangisi kazaların temelinde yatan 4M olarak adlandırılan 4 etkenden biri değildir?",
        "secenekler" : [ 
            {
                "dogru" : 1,
                "secenek" : "KKD"
            }, 
            {
                "dogru" : 0,
                "secenek" : "Makine"
            }, 
            {
                "dogru" : 0,
                "secenek" : "Yönetim"
            }, 
            {
                "dogru" : 0,
                "secenek" : "İnsan"
            } 
        ]
        
    },
    {
        "soru" : "Aşağıdakilerden hangisi genel iş güvenliği kurallarından biri değildir?",
        "secenekler" : [ 
            {
                "dogru" : 0,
                "secenek" : "Görevlendirilmediğiniz alana girmeyiniz, sorumluluğunuzda olmayan alet ve ekipmana dokunmayınız."
            }, 
            {
                "dogru" : 1,
                "secenek" : "Elleriniz cebinizde yürüyünüz."
            }, 
            {
                "dogru" : 0,
                "secenek" : "Bekleme yapan forklift, ekipman vs. arasından geçmeyiniz, operatörleri ile konuşmayınız."
            }, 
            {
                "dogru" : 0,
                "secenek" : "Fabrikada acil durumlar haricinde koşmayınız."
            } 
        ]
        
    },
    {
        "soru" : "Aşağıdakilerden hangisi gürültünün insan vücuduna olumsuz etkileri arasında yer almaz?",
        "secenekler" : [ 
            {
                "dogru" : 0,
                "secenek" : "Yorgunluk ve baş ağrısı şikayeti"
            }, 
            {
                "dogru" : 0,
                "secenek" : "Yüksek sesle konuşma alışkanlığı"
            }, 
            {
                "dogru" : 1,
                "secenek" : "Solunumun hızlanması"
            }, 
            {
                "dogru" : 0,
                "secenek" : "Sinirsel ve ruhsal rahatsızlıklar"
            } 
        ]
        
    },
    {
        "soru" : "Dönen ekipmanlar ile çalışmada aşağıdaki kişisel koruyuculardan hangisi kullanılmamalıdır?",
        "secenekler" : [ 
            {
                "dogru" : 0,
                "secenek" : "Tulum"
            }, 
            {
                "dogru" : 1,
                "secenek" : "İş eldiveni"
            }, 
            {
                "dogru" : 0,
                "secenek" : "İş gözlüğü"
            }, 
            {
                "dogru" : 0,
                "secenek" : "İş ayakkabısı"
            } 
        ]
        
    },
    {
        "soru" : "Güvenlik Sağlık İşaretleri Yönetmeliği’ne göre aşağıdakilerden hangisi yanlıştır?",
        "secenekler" : [ 
            {
                "dogru" : 0,
                "secenek" : "Uyarı-ikaz işaretleri sarı renklidir."
            }, 
            {
                "dogru" : 0,
                "secenek" : "Emredici işaretler mavi renklidir."
            }, 
            {
                "dogru" : 1,
                "secenek" : "Zorunluluk işaretleri yeşil renklidir."
            }, 
            {
                "dogru" : 0,
                "secenek" : "Yangınla mücadele ekipmanları kırmızı renklidir."
            } 
        ]
        
    },
    {
        "soru" : "Aşağıdakilerden hangisi ergonominin amaçlarından biri değildir?",
        "secenekler" : [ 
            {
                "dogru" : 0,
                "secenek" : "Yorulmayı ve iş stresini azaltmak"
            }, 
            {
                "dogru" : 0,
                "secenek" : "İş kazaları ve mesleki riskleri minimize etmek"
            }, 
            {
                "dogru" : 1,
                "secenek" : "İş yeri güvenliğini sağlamak"
            }, 
            {
                "dogru" : 0,
                "secenek" : "Verimlilik ve kaliteyi yükseltmek"
            } 
        ]
        
    }


]




        }
    },

    computed:{
        computedObj(){
            return this.limit ? this.winners.slice(0,this.limit) : this.winners
        }
    },

    async created() {
        let res = await import("@/translate/"+this.$route.meta.company+".json");
        let res2 = JSON.stringify(res);
        let translate = JSON.parse(res2);
        let translate_icerik = translate.en;
        this.logo = translate_icerik.logo;
    },

    methods:{
        getCompany(){
            this.company = this.$route.meta.company
            myCss(this.$route.meta.company);

            let nRef1 = this.company+"/cevaplar/1";
            get(child(dbRef, nRef1)).then((snp) => {
                snp.forEach(e => {
                    this.soru1_cevaplar.push({
                        key : e.key,
                        uid : e.val().uid,
                        index : e.val().index
                    });
                });
            });

            let nRef2 = this.company+"/cevaplar/2";
            get(child(dbRef, nRef2)).then((snp) => {
                snp.forEach(e => {
                    this.soru2_cevaplar.push({
                        key : e.key,
                        uid : e.val().uid,
                        index : e.val().index
                    });
                });
            });
            let nRef3 = this.company+"/cevaplar/3";
            get(child(dbRef, nRef3)).then((snp) => {
                snp.forEach(e => {
                    this.soru3_cevaplar.push({
                        key : e.key,
                        uid : e.val().uid,
                        index : e.val().index
                    });
                });
            });
            let nRef4 = this.company+"/cevaplar/4";
            get(child(dbRef, nRef4)).then((snp) => {
                snp.forEach(e => {
                    this.soru4_cevaplar.push({
                        key : e.key,
                        uid : e.val().uid,
                        index : e.val().index
                    });
                });
            });
            let nRef5 = this.company+"/cevaplar/5";
            get(child(dbRef, nRef5)).then((snp) => {
                snp.forEach(e => {
                    this.soru5_cevaplar.push({
                        key : e.key,
                        uid : e.val().uid,
                        index : e.val().index
                    });
                });
            });
            let nRef6 = this.company+"/cevaplar/6";
            get(child(dbRef, nRef6)).then((snp) => {
                snp.forEach(e => {
                    this.soru6_cevaplar.push({
                        key : e.key,
                        uid : e.val().uid,
                        index : e.val().index
                    });
                });
            });
            let nRef7 = this.company+"/cevaplar/7";
            get(child(dbRef, nRef7)).then((snp) => {
                snp.forEach(e => {
                    this.soru7_cevaplar.push({
                        key : e.key,
                        uid : e.val().uid,
                        index : e.val().index
                    });
                });
            });
            let nRef8 = this.company+"/cevaplar/8";
            get(child(dbRef, nRef8)).then((snp) => {
                snp.forEach(e => {
                    this.soru8_cevaplar.push({
                        key : e.key,
                        uid : e.val().uid,
                        index : e.val().index
                    });
                });
            });
            let nRef9 = this.company+"/cevaplar/9";
            get(child(dbRef, nRef9)).then((snp) => {
                snp.forEach(e => {
                    this.soru9_cevaplar.push({
                        key : e.key,
                        uid : e.val().uid,
                        index : e.val().index
                    });
                });
            });
            let nRef10 = this.company+"/cevaplar/10";
            get(child(dbRef, nRef10)).then((snp) => {
                snp.forEach(e => {
                    this.soru10_cevaplar.push({
                        key : e.key,
                        uid : e.val().uid,
                        index : e.val().index
                    });
                });
            });
            let nRef11 = this.company+"/cevaplar/11";
            get(child(dbRef, nRef11)).then((snp) => {
                snp.forEach(e => {
                    this.soru11_cevaplar.push({
                        key : e.key,
                        uid : e.val().uid,
                        index : e.val().index
                    });
                });
            });
            let nRef12 = this.company+"/cevaplar/12";
            get(child(dbRef, nRef12)).then((snp) => {
                snp.forEach(e => {
                    this.soru12_cevaplar.push({
                        key : e.key,
                        uid : e.val().uid,
                        index : e.val().index
                    });
                });
            });
            let nRef13 = this.company+"/cevaplar/13";
            get(child(dbRef, nRef13)).then((snp) => {
                snp.forEach(e => {
                    this.soru13_cevaplar.push({
                        key : e.key,
                        uid : e.val().uid,
                        index : e.val().index
                    });
                });
            });
            let nRef14 = this.company+"/cevaplar/14";
            get(child(dbRef, nRef14)).then((snp) => {
                snp.forEach(e => {
                    this.soru14_cevaplar.push({
                        key : e.key,
                        uid : e.val().uid,
                        index : e.val().index
                    });
                });
            });
            let nRef15 = this.company+"/cevaplar/15";
            get(child(dbRef, nRef15)).then((snp) => {
                snp.forEach(e => {
                    this.soru15_cevaplar.push({
                        key : e.key,
                        uid : e.val().uid,
                        index : e.val().index
                    });
                });
            });
            let nRef16 = this.company+"/cevaplar/16";
            get(child(dbRef, nRef16)).then((snp) => {
                snp.forEach(e => {
                    this.soru16_cevaplar.push({
                        key : e.key,
                        uid : e.val().uid,
                        index : e.val().index
                    });
                });
            });
            let nRef17 = this.company+"/cevaplar/17";
            get(child(dbRef, nRef17)).then((snp) => {
                snp.forEach(e => {
                    this.soru17_cevaplar.push({
                        key : e.key,
                        uid : e.val().uid,
                        index : e.val().index
                    });
                });
            });
            let nRef18 = this.company+"/cevaplar/18";
            get(child(dbRef, nRef18)).then((snp) => {
                snp.forEach(e => {
                    this.soru18_cevaplar.push({
                        key : e.key,
                        uid : e.val().uid,
                        index : e.val().index
                    });
                });
            });
            let nRef19 = this.company+"/cevaplar/19";
            get(child(dbRef, nRef19)).then((snp) => {
                snp.forEach(e => {
                    this.soru19_cevaplar.push({
                        key : e.key,
                        uid : e.val().uid,
                        index : e.val().index
                    });
                });
            });
            let nRef20 = this.company+"/cevaplar/20";
            get(child(dbRef, nRef20)).then((snp) => {
                snp.forEach(e => {
                    this.soru20_cevaplar.push({
                        key : e.key,
                        uid : e.val().uid,
                        index : e.val().index
                    });
                });
            });



            let nRef = this.company+"/users";
            get(child(dbRef, nRef)).then((snapshot) => {
                this.winners = [];

                snapshot.forEach(e => {

                    var soru1_cevap = "";
                    this.soru1_cevaplar.forEach(kk => {
                        if(kk.uid === e.key){
                            soru1_cevap = kk.index  ;
                        }
                    });

                    var soru2_cevap = "";
                    this.soru2_cevaplar.forEach(kk => {
                        if(kk.uid === e.key){
                            soru2_cevap = kk.index ;
                        }
                    });

                    var soru3_cevap = "";
                    this.soru3_cevaplar.forEach(kk => {
                        if(kk.uid === e.key){
                            soru3_cevap = kk.index ;
                        }
                    });
                    var soru4_cevap = "";
                    this.soru4_cevaplar.forEach(kk => {
                        if(kk.uid === e.key){
                            soru4_cevap = kk.index ;
                        }
                    });
                    var soru5_cevap = "";
                    this.soru5_cevaplar.forEach(kk => {
                        if(kk.uid === e.key){
                            soru5_cevap = kk.index ;
                        }
                    });
                    var soru6_cevap = "";
                    this.soru6_cevaplar.forEach(kk => {
                        if(kk.uid === e.key){
                            soru6_cevap = kk.index ;
                        }
                    });
                    var soru7_cevap = "";
                    this.soru7_cevaplar.forEach(kk => {
                        if(kk.uid === e.key){
                            soru7_cevap = kk.index ;
                        }
                    });
                    var soru8_cevap = "";
                    this.soru8_cevaplar.forEach(kk => {
                        if(kk.uid === e.key){
                            soru8_cevap = kk.index ;
                        }
                    });
                    
                    var soru9_cevap = "";
                    this.soru9_cevaplar.forEach(kk => {
                        if(kk.uid === e.key){
                            soru9_cevap = kk.index ;
                        }
                    });
                    var soru10_cevap = "";
                    this.soru10_cevaplar.forEach(kk => {
                        if(kk.uid === e.key){
                            soru10_cevap = kk.index ;
                        }
                    });
                    var soru11_cevap = "";
                    this.soru11_cevaplar.forEach(kk => {
                        if(kk.uid === e.key){
                            soru11_cevap = kk.index ;
                        }
                    });
                    var soru12_cevap = "";
                    this.soru12_cevaplar.forEach(kk => {
                        if(kk.uid === e.key){
                            soru12_cevap = kk.index ;
                        }
                    });
                    var soru13_cevap = "";
                    this.soru13_cevaplar.forEach(kk => {
                        if(kk.uid === e.key){
                            soru13_cevap = kk.index ;
                        }
                    });
                    var soru14_cevap = "";
                    this.soru14_cevaplar.forEach(kk => {
                        if(kk.uid === e.key){
                            soru14_cevap = kk.index ;
                        }
                    });
                    var soru15_cevap = "";
                    this.soru15_cevaplar.forEach(kk => {
                        if(kk.uid === e.key){
                            soru15_cevap = kk.index ;
                        }
                    });
                    var soru16_cevap = "";
                    this.soru16_cevaplar.forEach(kk => {
                        if(kk.uid === e.key){
                            soru16_cevap = kk.index ;
                        }
                    });
                    var soru17_cevap = "";
                    this.soru17_cevaplar.forEach(kk => {
                        if(kk.uid === e.key){
                            soru17_cevap = kk.index ;
                        }
                    });
                    var soru18_cevap = "";
                    this.soru18_cevaplar.forEach(kk => {
                        if(kk.uid === e.key){
                            soru18_cevap = kk.index ;
                        }
                    });
                    
                    var soru19_cevap = "";
                    this.soru19_cevaplar.forEach(kk => {
                        if(kk.uid === e.key){
                            soru19_cevap = kk.index ;
                        }
                    });
                    
                    var soru20_cevap = "";
                    this.soru20_cevaplar.forEach(kk => {
                        if(kk.uid === e.key){
                            soru20_cevap = kk.index ;
                        }
                    });
                    
                    


                    let temp = {
                        userId:e.key,
                        puan: e.val().puan,
                        name:e.val().name,
                        email:e.val().email,
                        soru_1 :  soru1_cevap === "" ? "" : this.questions[0].secenekler[soru1_cevap].secenek ,
                        soru_1_dogru :  soru1_cevap === "" ? "" : this.questions[0].secenekler[soru1_cevap].dogru ,

                        soru_2 :  soru2_cevap === "" ? "" : this.questions[1].secenekler[soru2_cevap].secenek ,
                        soru_2_dogru :  soru2_cevap === "" ? "" : this.questions[1].secenekler[soru2_cevap].dogru ,

                        soru_3 :  soru3_cevap === "" ? "" : this.questions[2].secenekler[soru3_cevap].secenek ,
                        soru_3_dogru :  soru3_cevap === "" ? "" : this.questions[2].secenekler[soru3_cevap].dogru ,

                        soru_4 :  soru4_cevap === "" ? "" : this.questions[3].secenekler[soru4_cevap].secenek ,
                        soru_4_dogru :  soru4_cevap === "" ? "" : this.questions[3].secenekler[soru4_cevap].dogru ,

                        soru_5 :  soru5_cevap === "" ? "" : this.questions[4].secenekler[soru5_cevap].secenek ,
                        soru_5_dogru :  soru5_cevap === "" ? "" : this.questions[4].secenekler[soru5_cevap].dogru ,

                        soru_6 :  soru6_cevap === "" ? "" : this.questions[5].secenekler[soru6_cevap].secenek ,
                        soru_6_dogru :  soru6_cevap === "" ? "" : this.questions[5].secenekler[soru6_cevap].dogru ,

                        soru_7 :  soru7_cevap === "" ? "" : this.questions[6].secenekler[soru7_cevap].secenek ,
                        soru_7_dogru :  soru7_cevap === "" ? "" : this.questions[6].secenekler[soru7_cevap].dogru ,

                        soru_8 :  soru8_cevap === "" ? "" : this.questions[7].secenekler[soru8_cevap].secenek ,
                        soru_8_dogru :  soru8_cevap === "" ? "" : this.questions[7].secenekler[soru8_cevap].dogru ,

                        soru_9 :  soru9_cevap === "" ? "" : this.questions[8].secenekler[soru9_cevap].secenek ,
                        soru_9_dogru :  soru9_cevap === "" ? "" : this.questions[8].secenekler[soru9_cevap].dogru ,

                        soru_10 :  soru10_cevap === "" ? "" : this.questions[9].secenekler[soru10_cevap].secenek ,
                        soru_10_dogru :  soru10_cevap === "" ? "" : this.questions[9].secenekler[soru10_cevap].dogru ,

                        soru_11 :  soru11_cevap === "" ? "" : this.questions[10].secenekler[soru11_cevap].secenek ,
                        soru_11_dogru :  soru11_cevap === "" ? "" : this.questions[10].secenekler[soru11_cevap].dogru ,

                        soru_12 :  soru12_cevap === "" ? "" : this.questions[11].secenekler[soru12_cevap].secenek ,
                        soru_12_dogru :  soru12_cevap === "" ? "" : this.questions[11].secenekler[soru12_cevap].dogru ,

                        soru_13 :  soru13_cevap === "" ? "" : this.questions[12].secenekler[soru13_cevap].secenek ,
                        soru_13_dogru :  soru13_cevap === "" ? "" : this.questions[12].secenekler[soru13_cevap].dogru ,

                        soru_14 :  soru14_cevap === "" ? "" : this.questions[13].secenekler[soru14_cevap].secenek ,
                        soru_14_dogru :  soru14_cevap === "" ? "" : this.questions[13].secenekler[soru14_cevap].dogru ,

                        soru_15 :  soru15_cevap === "" ? "" : this.questions[14].secenekler[soru15_cevap].secenek ,
                        soru_15_dogru :  soru15_cevap === "" ? "" : this.questions[14].secenekler[soru15_cevap].dogru ,

                        soru_16 :  soru16_cevap === "" ? "" : this.questions[15].secenekler[soru16_cevap].secenek ,
                        soru_16_dogru :  soru16_cevap === "" ? "" : this.questions[15].secenekler[soru16_cevap].dogru ,

                        soru_17 :  soru17_cevap === "" ? "" : this.questions[16].secenekler[soru17_cevap].secenek ,
                        soru_17_dogru :  soru17_cevap === "" ? "" : this.questions[16].secenekler[soru17_cevap].dogru ,

                        soru_18 :  soru18_cevap === "" ? "" : this.questions[17].secenekler[soru18_cevap].secenek ,
                        soru_18_dogru :  soru18_cevap === "" ? "" : this.questions[17].secenekler[soru18_cevap].dogru ,

                        soru_19 :  soru19_cevap === "" ? "" : this.questions[18].secenekler[soru19_cevap].secenek ,
                        soru_19_dogru :  soru19_cevap === "" ? "" : this.questions[18].secenekler[soru19_cevap].dogru ,

                        soru_20 :  soru20_cevap === "" ? "" : this.questions[19].secenekler[soru20_cevap].secenek ,
                        soru_20_dogru :  soru20_cevap === "" ? "" : this.questions[19].secenekler[soru20_cevap].dogru ,

                    };
                    this.winners.push(temp);

                }); 
            }).catch((error) => {
                console.error(error);
            });

        },

    },

    mounted () {
        this.getCompany();

        this.language =  this.$store.getters.getLanguage;
        this.email =  this.$store.getters.getEmail;
        this.uid =  this.$store.getters.getUid;
        this.name =  this.$store.getters.getName;

    },

}
</script>