<template>
    <div class="">
        <div class="container dw640 pb_80 color_white ">



            <div class="f_32 font_semibold mt-4 pt-4 ">Tebrikler!</div>
            <div class="f_18  mt-1 ">Yarışmayı başarıyla tamamladınız. İşte sonuçlar…</div>



            <div class="f_22 font_semibold text-center mt-2  py-3 " >SONUÇLAR</div>

            <b-list-group v-if="this.winners.length > 0" class=" round0" >
                <b-list-group-item   class="backgroundTrans  border_color_final" >

                    <div class="d-flex w-100 justify-content-between " >
                        <div class="d-flex  align-items-center ">
                            <div class="takim_kirmizi final_renk_w"></div>
                            <div class="font_semibold ml-2"> Kırmızı Takım</div>
                        </div>
                        <div class="">{{kirmizi}}</div>
                    </div>

                    <div class="d-flex w-100 justify-content-between mt-3" >
                        <div class="d-flex  align-items-center ">
                            <div class="takim_sari final_renk_w"></div>
                            <div class="font_semibold ml-2"> Sarı Takım</div>
                        </div>
                        <div class="">{{sari}}</div>
                    </div>

                    <div class="d-flex w-100 justify-content-between mt-3" >
                        <div class="d-flex  align-items-center ">
                            <div class="takim_mavi final_renk_w"></div>
                            <div class="font_semibold ml-2"> Mavi Takım</div>
                        </div>
                        <div class="">{{mavi}}</div>
                    </div>

                    <div class="d-flex w-100 justify-content-between mt-3" >
                        <div class="d-flex  align-items-center ">
                            <div class="takim_yesil  final_renk_w"></div>
                            <div class="font_semibold ml-2"> Yeşil Takım</div>
                        </div>
                        <div class="">{{yesil}}</div>
                    </div>

                    <div class="d-flex w-100 justify-content-between mt-3" >
                        <div class="d-flex  align-items-center ">
                            <div class="takim_mor  final_renk_w"></div>
                            <div class="font_semibold ml-2"> Mor Takım</div>
                        </div>
                        <div class="">{{mor}}</div>
                    </div>

                    <div class="d-flex w-100 justify-content-between mt-3" >
                        <div class="d-flex  align-items-center ">
                            <div class="takim_turuncu  final_renk_w"></div>
                            <div class="font_semibold ml-2"> Turuncu Takım</div>
                        </div>
                        <div class="">{{turuncu}}</div>
                    </div>



                </b-list-group-item>
            </b-list-group>


            <div class='text-center position-absolute' style="bottom:30px; left:0; right:0;">
                <img src="../../assets/deloitte-logo.png" width="140">
            </div>

        </div>
    </div>
</template>

<script>
import { initializeApp } from "firebase/app";
import { getDatabase,ref,  get, child } from "firebase/database";

const config = {
    apiKey: "AIzaSyDNmy0ZlEDGtMBNtXbTdxBNEi4bPklV1SQ",
    authDomain: "quizylive.firebaseapp.com",
    databaseURL: "https://quizylive-default-rtdb.firebaseio.com",
    projectId: "quizylive",
    storageBucket: "quizylive.appspot.com",
    messagingSenderId: "497024495958",
    appId: "1:497024495958:web:e79a3a7a61d0c313a0e6f7"
};

const f_app = initializeApp(config);
const db = getDatabase(f_app);
const dbRef = ref(db);
const myCss = () => import('@/styles/deloitte.css');

export default {

    data(){
        return {

            language: "en",
            company: "deloitte",
            email: '',
            uid: '',
            name: '',
            winners: [ ],
            logo: '',
            puan: 0,
            limit : 3,

            kirmizi:0,
            mavi:0,
            sari:0,
            mor:0,
            turuncu:0,
            yesil:0
        }
    },



    async created() {
        let res = await import("@/translate/"+this.$route.meta.company+".json");
        let res2 = JSON.stringify(res);
        let translate = JSON.parse(res2);
        let translate_icerik = translate.en;
        this.logo = translate_icerik.logo;
    },

    methods:{

        computedObj(){

            if(this.winners.length > 0){

                return this.winners.sort(this.compare)
            }
            else{
                return []
            }            

        },

        compare( a, b ) {
            if ( a.puan < b.puan ){
                return 1;
            }
            if ( a.puan > b.puan ){
                return -1;
            }
            return 0;
        },


        secClass(index){
            let cls = "d-flex w-100 justify-content-between  ";
            if(index <= 2){
                cls = "d-flex w-100 justify-content-between  ";
            }
            return cls
        },

        secClass2(renk){
            let ek = ""
            if(renk === "kirmizi"){ ek="takim_kirmizi" }
            if(renk === "yesil"){ ek="takim_yesil" }
            if(renk === "mavi"){ ek="takim_mavi" }
            if(renk === "sari"){ ek="takim_sari" }
            if(renk === "mor"){ ek="takim_mor" }
            if(renk === "turuncu"){ ek="takim_turuncu" }

            let cls = "final_renk_w " + ek;

            

            return cls
        },


        connectDb(){
            /*
            const refPuan = ref(db, "deloitte/users/"+this.uid+"/puan");
            onValue(refPuan, (snapshot) => {
                const data = snapshot.val();
                this.puan = data
            });
            */

        },

        getCompany(){



            this.company = this.$route.meta.company
            console.log(this.$route.meta.company);
            myCss(this.$route.meta.company);




            get(child(dbRef,"deloitte/takim_kirmizi")).then((snapshot) => {
                this.kirmizi = snapshot.val()

                this.winners.push({
                    puan: snapshot.val(),
                    name:"Kırmızı Takım",
                    renk:"kirmizi",
                })

            })
            get(child(dbRef,"deloitte/takim_sari")).then((snapshot) => {
                this.sari = snapshot.val()
                this.winners.push({
                    puan: snapshot.val(),
                    name:"Sarı Takım",
                    renk:"sari",
                })
            })
            get(child(dbRef,"deloitte/takim_mavi")).then((snapshot) => {
                this.mavi = snapshot.val()
                this.winners.push({
                    puan: snapshot.val(),
                    name:"Mavi Takım",
                    renk:"mavi",
                })
            })
            get(child(dbRef,"deloitte/takim_mor")).then((snapshot) => {
                this.mor = snapshot.val()
                this.winners.push({
                    puan: snapshot.val(),
                    name:"Mor Takım",
                    renk:"mor",
                })
            })
            get(child(dbRef,"deloitte/takim_yesil")).then((snapshot) => {
                this.yesil = snapshot.val()
                this.winners.push({
                    puan: snapshot.val(),
                    name:"Yeşil Takım",
                    renk:"yesil",
                })
            })
            get(child(dbRef,"deloitte/takim_turuncu")).then((snapshot) => {
                this.turuncu = snapshot.val()
                this.winners.push({
                    puan: snapshot.val(),
                    name:"Turuncu Takım",
                    renk:"turuncu",
                })
            })



            /*

            let nRef = this.company+"/winners";
            get(child(dbRef, nRef)).then((snapshot) => {
                this.winners = [];
                snapshot.forEach(e => {

                    let temp = {
                        userId:e.key,
                        puan: e.val().puan,
                        name:e.val().name,
                        email:e.val().email
                    };
                    this.winners.push(temp);

                }); 
            }).catch((error) => {
                console.error(error);
            });
            */

        },

    },

    computed:{
        
    },

    mounted () {
        this.getCompany();

        this.language =  this.$store.getters.getLanguage;
        this.email =  this.$store.getters.getEmail;
        this.uid =  this.$store.getters.getUid;
        this.name =  this.$store.getters.getName;

        this.connectDb();
    },

}
</script>