<template>
    <div class="logo_bg">
        <div class="container dw640 pb_150 color_white">


            <div class="f_22 font_semibold text-center mt-5">KAZANANLAR</div>
            <b-list-group class="mt-2" >
                <b-list-group-item v-for="item in computedObj" :key="item.userId"  class="backgroundTrans border_color_final" >
                    <div  class="d-flex w-100 justify-content-between">
                    <div class="font_semibold">{{item.name}}</div>
                    <div class="">{{item.puan}}</div>
                    </div>
                </b-list-group-item>
            </b-list-group>

        </div>
    </div>
</template>

<script>
import { initializeApp } from "firebase/app";
import { getDatabase,ref,get,child } from "firebase/database";
const config = {
    apiKey: "AIzaSyDNmy0ZlEDGtMBNtXbTdxBNEi4bPklV1SQ",
    authDomain: "quizylive.firebaseapp.com",
    databaseURL: "https://quizylive-default-rtdb.firebaseio.com",
    projectId: "quizylive",
    storageBucket: "quizylive.appspot.com",
    messagingSenderId: "497024495958",
    appId: "1:497024495958:web:e79a3a7a61d0c313a0e6f7"
};

const f_app = initializeApp(config);
const db = getDatabase(f_app);
const dbRef = ref(db);
const myCss = () => import('@/styles/deloitte.css');

export default {

    data(){
        return {

            language: "tr",
            company: "deloitte",
            email: '',
            uid: '',
            name: '',
            winners: [ ],
            logo: '',
            limit : 3
        }
    },

    computed:{
        computedObj(){
            return this.limit ? this.winners.slice(0,this.limit) : this.winners
        }
    },

    async created() {
        let res = await import("@/translate/"+this.$route.meta.company+".json");
        let res2 = JSON.stringify(res);
        let translate = JSON.parse(res2);
        let translate_icerik = translate.en;
        this.logo = translate_icerik.logo;
    },

    methods:{
        getCompany(){
            this.company = this.$route.meta.company
            console.log(this.$route.meta.company);
            myCss(this.$route.meta.company);

            let nRef = this.company+"/winners";
            get(child(dbRef, nRef)).then((snapshot) => {
                this.winners = [];
                snapshot.forEach(e => {

                    let temp = {
                        userId:e.key,
                        puan: e.val().puan,
                        name:e.val().name,
                        email:e.val().email
                    };
                    this.winners.push(temp);

                }); 
            }).catch((error) => {
                console.error(error);
            });

        },

    },

    mounted () {
        this.getCompany();

        this.language =  this.$store.getters.getLanguage;
        this.email =  this.$store.getters.getEmail;
        this.uid =  this.$store.getters.getUid;
        this.name =  this.$store.getters.getName;

    },

}
</script>