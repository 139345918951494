<template>
    <div class="">
        <div class="container cont_kisa pt-5">


            <div class="f_24 w600 mt-4 color_white">Hoş geldiniz!</div>
            <div class="mt-1 f_18 color_white">Lütfen takımınızı seçin.</div>

            <div class="row mt-4">

                <div @click="sec(1)" class="col-4 mb-4 mt-2"><div :class="secClass(1)"></div><div class="color_white w500 text-center">Kırmızı</div></div>
                <div @click="sec(2)" class="col-4 mb-4 mt-2"><div :class="secClass(2)"></div><div class="color_white w500 text-center">Yeşil</div></div>
                <div @click="sec(3)" class="col-4 mb-4 mt-2"><div :class="secClass(3)"></div><div class="color_white w500 text-center">Mavi</div></div>
                <div @click="sec(4)" class="col-4 mb-4 mt-2"><div :class="secClass(4)"></div><div class="color_white w500 text-center">Sarı</div></div>
                <div @click="sec(5)" class="col-4 mb-4 mt-2"><div :class="secClass(5)"></div><div class="color_white w500 text-center">Mor</div></div>
                <div @click="sec(6)" class="col-4 mb-4 mt-2"><div :class="secClass(6)"></div><div class="color_white w500 text-center">Turuncu</div></div>
                
                
            </div>

            <div class="d-grid gap-2">
                <button @click="loginYap" class="btn btn-block button_tur py-3 f_18 w600" type="button">DEVAM</button>
            </div>

            
            <div class='text-center position-absolute' style="bottom:50px; left:0; right:0;">
                <img src="../../assets/deloitte-logo.png" width="140">
            </div>



        </div>
    </div>
</template>

<script>

import { initializeApp } from "firebase/app";
import { getDatabase,get,child,ref,set } from "firebase/database";

const config = {
    apiKey: "AIzaSyDNmy0ZlEDGtMBNtXbTdxBNEi4bPklV1SQ",
    authDomain: "quizylive.firebaseapp.com",
    databaseURL: "https://quizylive-default-rtdb.firebaseio.com",
    projectId: "quizylive",
    storageBucket: "quizylive.appspot.com",
    messagingSenderId: "497024495958",
    appId: "1:497024495958:web:e79a3a7a61d0c313a0e6f7"
};
const f_app = initializeApp(config);
const db = getDatabase(f_app);
const dbRef = ref(db);


const myCss = () => import('@/styles/deloitte.css');


export default {

    data(){
        return {

            language: "tr",
            company: "deloitte",
            email: '',
            uid: '',
            name: '',
            //timeS: serverTimestamp(),
            gamestatus: 1,
            totalSoru: 16,
            openedSoru:0,
            puan: 0,
            puankazan: 0,
            secilen_takim: 0,

            logo: '',
            refresh_type: 'beyaz',

        }
    },



    async created() {

        let res = await import("@/translate/"+this.$route.meta.company+".json");
        let res2 = JSON.stringify(res);
        let translate = JSON.parse(res2);

        let translate_icerik = translate.en;

        this.logo = translate_icerik.logo;

    },

    methods:{

        sec(e){
            console.log(e);
            this.secilen_takim = e

        },
        secClass(index){
            let ek = ""
            if(index == 1){ ek="takim_kirmizi" }
            if(index == 2){ ek="takim_yesil" }
            if(index == 3){ ek="takim_mavi" }
            if(index == 4){ ek="takim_sari" }
            if(index == 5){ ek="takim_mor" }
            if(index == 6){ ek="takim_turuncu" }

            let cls = "takim_div " + ek;
            if(index == this.secilen_takim){
                cls = "takim_div_sec " + ek;
            }
            return cls
        },

        loginYap(){

            console.log("selected takim");
            console.log(this.secilen_takim);
            
            if(this.secilen_takim == 0){
                alert("Lütfen bir takım seçiniz");
            }else{
                console.log(this.uid);
                let ek = ""
                if(this.secilen_takim == 1){ ek="kirmizi" }
                if(this.secilen_takim == 2){ ek="yesil" }
                if(this.secilen_takim == 3){ ek="mavi" }
                if(this.secilen_takim == 4){ ek="sari" }
                if(this.secilen_takim == 5){ ek="mor" }
                if(this.secilen_takim == 6){ ek="turuncu" }
                
                set(ref(db, this.company+"/users/"+ this.uid+"/takim"),  ek).then(() => {
                    this.gotoLobby()
                });
            }

        },

        gotoLobby(){
            

            let nRef = "deloitte/openedSoru";
            get(child(dbRef, nRef)).then((snapshot) => {
                //this.winners = [];
                let openedSoru = snapshot.val();

                console.log("openedSoru" + openedSoru);

                if(openedSoru == 1){  this.$store.commit('set_q1_answered', 1); }
                if(openedSoru == 2){  this.$store.commit('set_q2_answered', 1); }
                if(openedSoru == 3){  this.$store.commit('set_q3_answered', 1); }
                if(openedSoru == 4){  this.$store.commit('set_q4_answered', 1); }
                if(openedSoru == 5){  this.$store.commit('set_q5_answered', 1); }
                if(openedSoru == 6){  this.$store.commit('set_q6_answered', 1); }
                if(openedSoru == 7){  this.$store.commit('set_q7_answered', 1); }
                if(openedSoru == 8){  this.$store.commit('set_q8_answered', 1); }
                if(openedSoru == 9){  this.$store.commit('set_q9_answered', 1); }
                if(openedSoru == 10){  this.$store.commit('set_q10_answered', 1); }
                if(openedSoru == 11){  this.$store.commit('set_q11_answered', 1); }
                if(openedSoru == 12){  this.$store.commit('set_q12_answered', 1); }
                if(openedSoru == 13){  this.$store.commit('set_q13_answered', 1); }
                if(openedSoru == 14){  this.$store.commit('set_q14_answered', 1); }
                if(openedSoru == 15){  this.$store.commit('set_q15_answered', 1); }
                if(openedSoru == 16){  this.$store.commit('set_q16_answered', 1); }
                if(openedSoru == 17){  this.$store.commit('set_q17_answered', 1); }
                if(openedSoru == 18){  this.$store.commit('set_q18_answered', 1); }
                if(openedSoru == 19){  this.$store.commit('set_q19_answered', 1); }
                if(openedSoru == 20){  this.$store.commit('set_q20_answered', 1); }
                

            }).then(() => {
                this.$router.push('/deloitte/lobby');
            });


        },
        getCompany(){
            //this.company = this.$route.meta.company
            //console.log(this.$route.meta.company);
            myCss(this.$route.meta.company);
        },

        limitText(text,len){
            return  text.length > len ? text.substring(0, len - 3) + "..." : text;
        },

        connectDb(){

            /*

            const refPuan = ref(db, "deloitte/users/"+this.uid+"/puan");
            onValue(refPuan, (snapshot) => {
                const data = snapshot.val();
                this.puan = data
            });

            const refPuanKazan = ref(db, "deloitte/users/"+this.uid+"/puankazan");
            onValue(refPuanKazan, (snapshot) => {
                const data = snapshot.val();
                this.puankazan = data
            });

            const refgamestatus = ref(db, "deloitte/status");
            onValue(refgamestatus, (snapshot) => {
                const data = snapshot.val();
                console.log(data);
                if(data == 0){
                    this.$router.push('/deloitte');
                }
                this.gamestatus = data
            });
            
            const reftotalSoru = ref(db, "deloitte/totalSoru");
            onValue(reftotalSoru, (snapshot) => {
                const data = snapshot.val();
                this.totalSoru = data
            });

            const refactiveSoru = ref(db, "deloitte/openedSoru");
            onValue(refactiveSoru, (snapshot) => {
                const data = snapshot.val();
                this.openedSoru = data
            });

            */
        },
        showToast(gelen){
            this.$bvToast.toast(gelen, {
                title: this.text_login_toast_title,
                autoHideDelay: 3000,
                appendToast: true,
                variant: 'danger',
                solid: true,
            })
        },

        animateCSS(element, animation, prefix = 'animate__') {
            new Promise((resolve) => {
                const animationName = `${prefix}${animation}`;
                const node = document.querySelector(element);
                node.classList.add(`${prefix}animated`, animationName);
                function handleAnimationEnd(event) {
                    event.stopPropagation();
                    node.classList.remove(`${prefix}animated`, animationName);
                    resolve('Animation ended');
                }
                node.addEventListener('animationend', handleAnimationEnd, {once: true});
            });
        },

        goToGame(){

            if(this.openedSoru == 1){this.$store.commit('set_q1_time', this.findNow());}
            if(this.openedSoru == 2){this.$store.commit('set_q2_time', this.findNow());}
            if(this.openedSoru == 3){this.$store.commit('set_q3_time', this.findNow());}
            if(this.openedSoru == 4){this.$store.commit('set_q4_time', this.findNow());}
            if(this.openedSoru == 5){this.$store.commit('set_q5_time', this.findNow());}
            if(this.openedSoru == 6){this.$store.commit('set_q6_time', this.findNow());}
            if(this.openedSoru == 7){this.$store.commit('set_q7_time', this.findNow());}
            if(this.openedSoru == 8){this.$store.commit('set_q8_time', this.findNow());}
            if(this.openedSoru == 9){this.$store.commit('set_q9_time', this.findNow());}
            if(this.openedSoru == 10){this.$store.commit('set_q10_time', this.findNow());}
            if(this.openedSoru == 11){this.$store.commit('set_q11_time', this.findNow());}
            if(this.openedSoru == 12){this.$store.commit('set_q12_time', this.findNow());}
            if(this.openedSoru == 13){this.$store.commit('set_q13_time', this.findNow());}
            if(this.openedSoru == 14){this.$store.commit('set_q14_time', this.findNow());}
            if(this.openedSoru == 15){this.$store.commit('set_q15_time', this.findNow());}
            if(this.openedSoru == 16){this.$store.commit('set_q16_time', this.findNow());}
            if(this.openedSoru == 17){this.$store.commit('set_q17_time', this.findNow());}
            if(this.openedSoru == 18){this.$store.commit('set_q18_time', this.findNow());}
            if(this.openedSoru == 19){this.$store.commit('set_q19_time', this.findNow());}
            if(this.openedSoru == 20){this.$store.commit('set_q20_time', this.findNow());}


            //console.log("openedSoru "+ this.openedSoru);
            
            this.$router.push('/deloitte/game');

        },
        findNow(){
            return Math.round(new Date().getTime()/1000);
        },

        

    },

    mounted () {
        this.getCompany();
        

        this.language =  this.$store.getters.getLanguage;
        this.email =  this.$store.getters.getEmail;
        this.uid =  this.$store.getters.getUid;
        this.name =  this.$store.getters.getName;

        this.connectDb();

        

    },

    watch: {

        

    },
    
    
    
}
</script>