<template>
    <div class="">
        <div class="container cont_kisa pt-5">




            <div class="f_24 w600 mt-4 color_white">Hoş geldiniz!</div>
            <div class="mt-1 f_18 color_white">Lütfen formu doldurarak giriş yapınız.</div>

            <div class='text-center position-absolute' style="bottom:50px; left:0; right:0;">
                <img src="../../assets/deloitte-logo.png" width="140">
            </div>

            <div class="row mt-4">

                <div class="col-12">

                    <div id="logindiv">
                    
                        <div class="color_white mt-2">



                            <div class="mb-3 ">
                                <div class="font_medium mb-1">Adınız, Soyadınız</div>
                                <input type="text" v-model="ad" class="form-control formborder f_18   px-3" style=" height:auto; padding-top: 12px; padding-bottom:12px" />
                            </div>

                            <div class="mb-4 ">
                                <div class="font_medium mb-1">Email</div>
                                <input v-model="email"  class="form-control formborder f_18   px-3"  style=" height:auto; padding-top: 12px; padding-bottom:12px"/>
                            </div>

                            <div class="mb-4 ">
                                Giriş yaparak Katılım Koşulları ve Gizlilik İlkelerini kabul etmiş olursunuz.
                            </div>

                            <div class="d-grid gap-2">
                                <button @click="loginYap" class="btn btn-block button_tur py-3 f_18 w600" type="button">GİRİŞ YAP</button>
                            </div>

                            

                            
                        </div>

                    </div>



                </div>


            </div>


            <b-modal ref="my-modal" hide-footer size="lg" >
                <div class="d-block color_siyah">

                    <keep-alive>
                    <component :is="currentTerm"></component>
                    </keep-alive>

                </div>
            </b-modal>




        </div>
    </div>
</template>

<script>

import bos from "@/assets/checkbox-bos_beyaz.png"
import dolu from "@/assets/checkbox-dolu_beyaz.png"


import { initializeApp } from "firebase/app";
import { getDatabase,get,child,ref,set } from "firebase/database";
//import { getDatabase,ref,set,onValue,get,child } from "firebase/database";

const config = {
    apiKey: "AIzaSyDNmy0ZlEDGtMBNtXbTdxBNEi4bPklV1SQ",
    authDomain: "quizylive.firebaseapp.com",
    databaseURL: "https://quizylive-default-rtdb.firebaseio.com",
    projectId: "quizylive",
    storageBucket: "quizylive.appspot.com",
    messagingSenderId: "497024495958",
    appId: "1:497024495958:web:e79a3a7a61d0c313a0e6f7"
};

const f_app = initializeApp(config);
const db = getDatabase(f_app);
const dbRef = ref(db);



const myCss = () => import('@/styles/deloitte.css');

export default {

    components: {},

    data(){
        return {

            componentToDisplay: null,
            checkbox: true,
            checkbox_img : bos,
            language: "tr",
            flag: "",
            company: "deloitte",
            email: '',
            ad: '',
            soyad: '',

            checkbox_type: 'beyaz',

            logo: '',
            firma_logo: '',
            
            text_login_hos: "",
            text_login_sub: "",
            text_giris_kodunuz: "",
            text_sozlesme: "",
            text_login_toast_title: "",
            text_login_toast_katilim: "",
            text_login_toast_email: ""
        }
    },

    computed: {

        currentTerm: function() {
            if (this.componentToDisplay) {
                return () => import(`@/translate/${this.componentToDisplay}`);
            }
            return null;
        }

    },

    async created() {

        let res = await import("@/translate/"+this.$route.meta.company+".json");
        let res2 = JSON.stringify(res);
        let translate = JSON.parse(res2);

        let translate_icerik = translate.tr;
        if(this.language == "en"){
            translate_icerik = translate.en;
        }

        this.text_login_hos = translate_icerik.login_hos;
        this.text_login_sub = translate_icerik.login_sub;
        this.text_giris_kodunuz = translate_icerik.giris_kodunuz;
        this.text_sozlesme = translate_icerik.sozlesme;
        this.text_login_toast_title = translate_icerik.login_toast_title;
        this.text_login_toast_katilim = translate_icerik.login_toast_katilim;
        this.text_login_toast_email = translate_icerik.login_toast_email;
        this.logo = translate_icerik.logo;
        this.firma_logo = translate_icerik.firma_logo;
        this.checkbox_type = translate_icerik.checkbox_type;

        this.checkbox_img = bos;
    },

    methods:{
        getCompany(){
            this.company = this.$route.meta.company
            console.log(this.$route.meta.company);
            myCss(this.$route.meta.company);
        },
        showModal() {
            this.componentToDisplay = "terms_"+this.$route.meta.company+"_"+this.language+".vue";
            this.$refs['my-modal'].show()
        },
        okudum(){
            if(this.checkbox){
                this.checkbox = false;
                this.checkbox_img = bos;
                
            }else{
                this.checkbox = true;
                this.checkbox_img = dolu;
            }
        },
        loginYap(){

            let ready =  true;
            if(this.email.length <= 1){
                this.showToast("Lütfen email adresinizi giriniz!");
                ready = false
            }
            if(this.ad == ""){
                this.showToast("Lütfen adınızı giriniz!");
                ready = false
            }

            
            if(ready){


                let _refstatus = "deloitte/status";

                get(child(dbRef,_refstatus)).then((snapshot) => {

                        if(snapshot.val() == "0"){
                            this.showToast("Yarışma henüz başlamadı, lütfen birazdan tekrar deneyin.");
                        }else{
                            this.girisYap();
                        }

                    }).catch((error) => {
                    console.log(error);
                });

            }
            
        },
        showToast(gelen){
            this.$bvToast.toast(gelen, {
                title: this.text_login_toast_title,
                autoHideDelay: 3000,
                appendToast: true,
                variant: 'danger',
                solid: true,
            })
        },
        changeLang(gelen){
            if(gelen == "tr"){
                this.flag = "turkish"
            }else{
                this.flag = "english"
            }
            this.language = gelen
            this.$store.commit('setLanguage', gelen);
            window.location.reload()
        },

        girisYap(){


            let rr = this.randName();
            let nn = this.ad

            set(ref(db, "deloitte/users/" + rr), {
                name: nn,
                email: this.email.toLowerCase(),
                active:1,
                puan:0,
                puankazan:0
            });

            this.$store.commit('setUid', rr);
            this.$store.commit('setEmail', this.email.toLowerCase());
            this.$store.commit('setName', nn);

            this.$store.commit('set_q1_answered', 0);
            this.$store.commit('set_q2_answered', 0);
            this.$store.commit('set_q3_answered', 0);
            this.$store.commit('set_q4_answered', 0);
            this.$store.commit('set_q5_answered', 0);
            this.$store.commit('set_q6_answered', 0);
            this.$store.commit('set_q7_answered', 0);
            this.$store.commit('set_q8_answered', 0);
            this.$store.commit('set_q9_answered', 0);
            this.$store.commit('set_q10_answered', 0);
            this.$store.commit('set_q11_answered', 0);
            this.$store.commit('set_q12_answered', 0);
            this.$store.commit('set_q13_answered', 0);
            this.$store.commit('set_q14_answered', 0);
            this.$store.commit('set_q15_answered', 0);
            this.$store.commit('set_q16_answered', 0);
            this.$store.commit('set_q17_answered', 0);
            this.$store.commit('set_q18_answered', 0);
            this.$store.commit('set_q19_answered', 0);
            this.$store.commit('set_q20_answered', 0);


            this.$store.commit('set_q1_time', 0);
            this.$store.commit('set_q2_time', 0);
            this.$store.commit('set_q3_time', 0);
            this.$store.commit('set_q4_time', 0);
            this.$store.commit('set_q5_time', 0);
            this.$store.commit('set_q6_time', 0);
            this.$store.commit('set_q7_time', 0);
            this.$store.commit('set_q8_time', 0);
            this.$store.commit('set_q9_time', 0);
            this.$store.commit('set_q10_time', 0);
            this.$store.commit('set_q11_time', 0);
            this.$store.commit('set_q12_time', 0);
            this.$store.commit('set_q13_time', 0);
            this.$store.commit('set_q14_time', 0);
            this.$store.commit('set_q15_time', 0);
            this.$store.commit('set_q16_time', 0);
            this.$store.commit('set_q17_time', 0);
            this.$store.commit('set_q18_time', 0);
            this.$store.commit('set_q19_time', 0);
            this.$store.commit('set_q20_time', 0);

            this.gotoTakim();


        },

        gotoTakim(){
            this.$router.push('/deloitte/takim');
        },

        gotoLobby(){
            

            let nRef = "deloitte/openedSoru";
            get(child(dbRef, nRef)).then((snapshot) => {
                this.winners = [];
                let openedSoru = snapshot.val();

                console.log("openedSoru" + openedSoru);

                if(openedSoru == 1){  this.$store.commit('set_q1_answered', 1); }
                if(openedSoru == 2){  this.$store.commit('set_q2_answered', 1); }
                if(openedSoru == 3){  this.$store.commit('set_q3_answered', 1); }
                if(openedSoru == 4){  this.$store.commit('set_q4_answered', 1); }
                if(openedSoru == 5){  this.$store.commit('set_q5_answered', 1); }
                if(openedSoru == 6){  this.$store.commit('set_q6_answered', 1); }
                if(openedSoru == 7){  this.$store.commit('set_q7_answered', 1); }
                if(openedSoru == 8){  this.$store.commit('set_q8_answered', 1); }
                if(openedSoru == 9){  this.$store.commit('set_q9_answered', 1); }
                if(openedSoru == 10){  this.$store.commit('set_q10_answered', 1); }
                if(openedSoru == 11){  this.$store.commit('set_q11_answered', 1); }
                if(openedSoru == 12){  this.$store.commit('set_q12_answered', 1); }
                if(openedSoru == 13){  this.$store.commit('set_q13_answered', 1); }
                if(openedSoru == 14){  this.$store.commit('set_q14_answered', 1); }
                if(openedSoru == 15){  this.$store.commit('set_q15_answered', 1); }
                if(openedSoru == 16){  this.$store.commit('set_q16_answered', 1); }
                if(openedSoru == 17){  this.$store.commit('set_q17_answered', 1); }
                if(openedSoru == 18){  this.$store.commit('set_q18_answered', 1); }
                if(openedSoru == 19){  this.$store.commit('set_q19_answered', 1); }
                if(openedSoru == 20){  this.$store.commit('set_q20_answered', 1); }
                

            }).then(() => {
                this.$router.push('/deloitte/lobby');
            });


            

        },



        randName(){
            var result           = '';
            var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < 12; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
            }
            return result;
        }



    },

    mounted () {
        this.getCompany();
        
        /*
        this.language =  this.$store.getters.getLanguage;
        
        if(this.language == "tr"){
            this.flag = turkish
        }else{
            this.flag = english
        }
        */

    },

    


};


</script>

