
<template>
<div class="container cont_kisa mt-5">
    
    
    <b-row class="my-1">
        <b-col sm="4">
            <label for="input-small">Oyun:</label>
        </b-col>
        <b-col sm="8">
        <b-form-input v-model="name" id="input-small" size="sm" ></b-form-input>
        </b-col>
    </b-row>

    <b-row class="my-1">
        <b-col sm="4">
            <label for="input-small">Admin Passsword:</label>
        </b-col>
        <b-col sm="8">
        <b-form-input v-model="Adminpassword" id="input-small" size="sm" ></b-form-input>
        </b-col>
    </b-row>

    <b-row class="my-1">
        <b-col sm="6">
            <label for="input-small">Liste var mı?: (0 yok, 1 var)</label>
        </b-col>
        <b-col sm="6">
        <b-form-input v-model="liste" id="input-small" size="sm" ></b-form-input>
        </b-col>
    </b-row>




    <b-row class="my-1">
        <b-col class="text-right" sm="12">
            <a href="#" @click="addGame" class="btn btn-primary">EKLE</a>
        </b-col>
    </b-row>
</div>
</template>


<style lang="scss">
@import '~@/styles/home.css';
</style>

<script>

import { initializeApp } from "firebase/app";
import { getDatabase,set,ref } from "firebase/database";


const config = {
    apiKey: "AIzaSyDNmy0ZlEDGtMBNtXbTdxBNEi4bPklV1SQ",
    authDomain: "quizylive.firebaseapp.com",
    databaseURL: "https://quizylive-default-rtdb.firebaseio.com",
    projectId: "quizylive",
    storageBucket: "quizylive.appspot.com",
    messagingSenderId: "497024495958",
    appId: "1:497024495958:web:e79a3a7a61d0c313a0e6f7"
};



const f_app = initializeApp(config);
const db = getDatabase(f_app);
//const dbRef = ref(db);


export default {

    components:{
        //HeaderBar
    },

    data(){
        return {
            name: "",
            Adminpassword: "",
            liste: ""
        }
    },

    methods:{
        addGame(){
            console.log(this.name);


            set(ref(db, this.name), {
                adminPassword: this.Adminpassword,
                gameId: this.randName(),
                listeReady : parseInt(this.liste),
                status: 1,
                totalSoru : 0,
                openedSoru : 0

            });


        },
        randName(){
            var result           = '';
            var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < 12; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
            }
            return result;
        }
    },

    mounted () {
        //this.getWeatherData()
    },
};


</script>

