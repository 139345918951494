<template>
    <div class=" m-4 color_white">


        <div class=" p-3 my-4">
            <span>Giriş Yapan Toplam Oyuncu: {{toplamGiren}}</span>
        </div>


        <div class="row">
            <div class="col-2 text-right">


            </div>
            <div class="col-8 " >
                
                <div class=" text-center rounded p-3">
                    <div>SORU {{sira+1}}</div>
                    <div class="mb-5 f_26 font_semibold">{{questions[sira].question}}</div>

                    <div class="border rounded p-2 m-2 f_20" :class="clasbul(item.dogru)" v-for="(item, index) in questions[sira].secenekler" :key="index">
                        {{item.secenek}} <br> ({{item.secildi}})
                    </div>

                    <div class="mt-5 " style="color:#00CCF9">
                        Soruda en yüksek puanı alan
                        <div class="font_semibold f_24">{{questions[sira].max_user}} - {{questions[sira].max_puan}}</div>
                    </div>

                </div>
            </div>
            <div class="col-2 ">

            </div>


        </div>

    </div>
</template>


<style >
body{
    color: #FFF !important;
    font-family: 'Poppins', sans-serif;
    font-size: 16px ;
    background-size:  auto 100%;
    overflow-x: hidden;
    color: #000;
}

h4, .h4 {
    font-size: 18px !important ;
    font-family: 'Poppins', sans-serif; font-weight:600 !important;
}

.font_regular{ font-family: 'Poppins', sans-serif; font-weight:400 !important;}
.font_medium{ font-family: 'Poppins', sans-serif; font-weight:500 !important;}
.font_semibold{ font-family: 'Poppins', sans-serif; font-weight:600 !important;}


.bg_scor{
    background-color: #00CCF9 !important;
    color: #011446;
}

.f_14{font-size: 14px !important;}
.f_16{font-size: 16px !important;}
.f_18{font-size: 18px !important;}
.f_20{font-size: 20px !important;}
.f_22{font-size: 22px !important;}
.f_24{font-size: 24px !important;}
.f_26{font-size: 26px !important;}


.number_box{
    width: 40px;
    height: 40px;
    float: left;
    margin-right: 9px;
    margin-bottom: 10px;
    background-color: #f5f5f5;
    border: 1px #CCC solid;
    text-align: center;
    padding-top: 8px;
    cursor: pointer;
}
.number_box:hover{
    background-color: #7aa0d9;
    border: 1px #426fb2 solid;

}

.number_box_selected, .number_box_selected:hover{
    width: 40px;
    height: 40px;
    float: left;
    margin-right: 9px;
    margin-bottom: 10px;
    background-color: #ff7100;
    border: 1px #ff7100 solid;
    text-align: center;
    padding-top: 8px;
    cursor: pointer;
    color: #FFF;
}



.round_box{
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}
</style>

<script>


import { initializeApp } from "firebase/app";
import { getDatabase,ref,get, child, onValue} from "firebase/database";

const config = {
    apiKey: "AIzaSyDNmy0ZlEDGtMBNtXbTdxBNEi4bPklV1SQ",
    authDomain: "quizylive.firebaseapp.com",
    databaseURL: "https://quizylive-default-rtdb.firebaseio.com",
    projectId: "quizylive",
    storageBucket: "quizylive.appspot.com",
    messagingSenderId: "497024495958",
    appId: "1:497024495958:web:e79a3a7a61d0c313a0e6f7"
};

const f_app = initializeApp(config);
const db = getDatabase(f_app);
const dbRef = ref(db);

const myCss = () => import('@/styles/deloitte.css');


export default {

    data(){
        return {
            company: "deloitte",
            secret: "",
            questions: [],
            sira: 0,
            users : [],
            gamestatus: 1,
            toplamGiren: 1,

        }
    },

    methods:{

        siraPos(){
            this.sira++;
            if(this.sira >= 19){
                this.sira = 19
            }
            return false;
        },

        siraNeg(){
            this.sira--;
            if(this.sira <= 0){
                this.sira = 0
            }
            return false;
        },


        clasbul(gelen){
            let res = "";
            if(gelen == "1"){
                res="bg_scor";
            }
            return res;
        },

        getCompany(){
            this.company = this.$route.meta.company
            myCss();
        },

        topla(){
            
            let sec1 = 0;
            let sec2 = 0;
            let sec3 = 0;
            let sec4 = 0;

            let nRef = "deloitte/cevaplar";
            get(child(dbRef, nRef)).then((snapshot) => {

                snapshot.forEach(e => {



                    if(e.val().question == (this.sira+1)){
                        if(e.val().index == "0"){sec1++}
                        if(e.val().index == "1"){sec2++}
                        if(e.val().index == "2"){sec3++}
                        if(e.val().index == "3"){sec4++}
                    }

                }); 

                
            }).then(()=>{

                this.questions[this.sira].secenekler[0].secildi =sec1
                this.questions[this.sira].secenekler[1].secildi =sec2
                this.questions[this.sira].secenekler[2].secildi =sec3
                this.questions[this.sira].secenekler[3].secildi =sec4

            }).catch((error) => {
                console.error(error);
            });


        }




    },

    mounted () {
        this.getCompany();

        /*
        window.setInterval(() => {
                this.topla()
        }, 5000);
        */

        const refgamestatus = ref(db, "deloitte/status");
        onValue(refgamestatus, (snapshot) => {
            const data = snapshot.val();
            this.gamestatus = data
        });

        let nRef =  ref(db, "deloitte/questions");
        onValue(nRef, (snapshot) => {
            this.questions = [];


            //this.questions = snapshot.val();

            snapshot.val().forEach(e => {

                if(e.question != undefined){

                    

                    let temp = {
                        secenekler: e.secenekler,
                        question: e.question,
                        max_user: e.max_user,
                        max_puan: e.max_puan,

                    };
                    this.questions.push(temp);
                }
            }); 

            
        });


        const refactiveSoru = ref(db, "deloitte/toplamGiren");
        onValue(refactiveSoru, (snapshot) => {
            this.toplamGiren = snapshot.val()
        });

        const reopenedSoruru = ref(db, "deloitte/openedSoru");
        onValue(reopenedSoruru, (snapshot) => {
            this.sira = snapshot.val()-1
        });

        



    },

    watch: {

        gamestatus:function(val){
            console.log(val);
            if (val == 2) {
                this.$router.push('/deloitte/finalsunucu');
            }
        },


    },


    

}
</script>