
<template>
<div class="container cont_kisa mt-5">
    
    
    <b-list-group class="mt-2" >
                <b-list-group-item v-for="item in users" :key="item.userId"  class="backgroundTrans border_color_final" >
                    
                    
                    <div  class="d-flex w-100 justify-content-between">
                    <div class="font_semibold">{{item.userId}}</div>
                    <div class="font_semibold">{{item.name}}</div>
                    <div class="">{{item.puan}}</div>
                    </div>
                    
                </b-list-group-item>
            </b-list-group>



            <b-list-group class="mt-2" >
                <b-list-group-item v-for="item in answers" :key="item.uid"  class="backgroundTrans border_color_final" >
                    
                    
                    <div  class="d-flex w-100 justify-content-between">
                    <div class="font_semibold">{{item.uid}}</div>
                    <div class="font_semibold">{{item.question}}</div>
                    <div class="">{{item.index}}</div>
                    </div>
                    
                </b-list-group-item>
            </b-list-group>
</div>
</template>


<style lang="scss">
@import '~@/styles/home.css';
</style>

<script>

import { initializeApp } from "firebase/app";
import { getDatabase,set,ref,get,child } from "firebase/database";


const config = {
    apiKey: "AIzaSyDNmy0ZlEDGtMBNtXbTdxBNEi4bPklV1SQ",
    authDomain: "quizylive.firebaseapp.com",
    databaseURL: "https://quizylive-default-rtdb.firebaseio.com",
    projectId: "quizylive",
    storageBucket: "quizylive.appspot.com",
    messagingSenderId: "497024495958",
    appId: "1:497024495958:web:e79a3a7a61d0c313a0e6f7"
};



const f_app = initializeApp(config);
const db = getDatabase(f_app);
const dbRef = ref(db);


export default {

    components:{
        //HeaderBar
    },

    data(){
        return {
            name: "",
            Adminpassword: "",
            liste: "",
            users : [],
            answers: []
        }
    },

    methods:{

        getData2(){

            let nRef = "demo/cevaplar";
            get(child(dbRef, nRef)).then((snapshot) => {
                this.answers = [];
                snapshot.forEach(e => {

                    if(e.val().uid == 'zn1c8p23tgopvne'){
                        let temp = {
                            uid: e.val().uid,
                            question:e.val().question,
                            index:e.val().index
                        };

                        console.log(temp);
                        this.answers.push(temp);
                    }


                }); 
            }).catch((error) => {
                console.error(error);
            });

        },
        //zn1c8p23tgopvne

        getData(){


                
            let nRef = "demo/users";
            get(child(dbRef, nRef)).then((snapshot) => {
                this.users = [];
                snapshot.forEach(e => {

                    let temp = {
                        userId:e.key,
                        puan: e.val().puan,
                        name:e.val().name,
                        email:e.val().email
                    };

                    console.log(temp);
                    this.users.push(temp);

                }); 
            }).catch((error) => {
                console.error(error);
            });

        },
        addGame(){
            console.log(this.name);


            set(ref(db, this.name), {
                adminPassword: this.Adminpassword,
                gameId: this.randName(),
                listeReady : parseInt(this.liste),
                status: 1,
                totalSoru : 16,
                openedSoru : 0

            });


        },
        randName(){
            var result           = '';
            var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < 12; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
            }
            return result;
        }
    },

    mounted () {
        //this.getData()
        this.getData2()
    },
};


</script>

